var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { Checkbox, DefaultButton, PrimaryButton, Stack, TextField } from '@fluentui/react';
import { stackTokens } from '../styles';
import * as React from 'react';
import { useCallback, useEffect, useState } from 'react';
import { getActiveCashAccounts, getCashRecords, saveCashRecords } from '../../services/server-api';
import { collapseISODate, expandISODate, todayAsISODate } from '../../common/date.utils';
import { CurrencyInput } from '../currency-input';
export var AddCashRecord = function (_a) {
    var onClose = _a.onClose, id = _a.id;
    var _b = useCashRecordsForm(onClose, id), draft = _b.draft, accounts = _b.accounts, updateDate = _b.updateDate, updateAccount = _b.updateAccount, submitForm = _b.submitForm;
    var _c = useState(false), showAllAccounts = _c[0], setShowAllAccounts = _c[1];
    //if editing an existing CAR, changing the date isn't allowed becase it'll just create a new
    //CAR set without deleting the old. So you must delete the old manually by clearing the inputs
    //and create a new one.
    var allowSelectingDate = !id;
    return (React.createElement("form", null,
        React.createElement(Stack, { tokens: stackTokens },
            allowSelectingDate &&
                React.createElement(TextField, { label: 'Date', type: "date", onChange: updateDate, value: expandISODate(draft.recordDate), name: "recordDate" }),
            React.createElement(Checkbox, { label: "Show all accounts", checked: showAllAccounts, onChange: function (_, checked) { return setShowAllAccounts(checked); } }), accounts === null || accounts === void 0 ? void 0 :
            accounts.filter(function (a) { return showAllAccounts || a.active || typeof getAccountValue(draft, a) !== 'undefined'; }).map(function (account) {
                return React.createElement(CurrencyInput, { key: account.id, label: "".concat(account.name, " ").concat(!account.active ? '(Inactive)' : ''), amount: getAccountValue(draft, account), currency: account.currency, name: "account_".concat(account.id), onChange: updateAccount });
            }),
            React.createElement(Stack, { horizontal: true, tokens: stackTokens },
                React.createElement(PrimaryButton, { onClick: submitForm }, "Save"),
                React.createElement(DefaultButton, { onClick: onClose }, "Cancel")))));
};
function getAccountValue(draft, account) {
    //zeroes should be shown, other falsy values skipped
    return draft.accounts[account.id];
}
function useCashRecordsForm(onClose, recordDate) {
    var _this = this;
    var _a = useState(), accounts = _a[0], setAccounts = _a[1];
    var _b = useState({
        recordDate: recordDate || todayAsISODate(),
        accounts: {}
    }), draft = _b[0], setDraft = _b[1];
    useEffect(function () {
        getActiveCashAccounts().then(setAccounts);
    }, []);
    useEffect(function () {
        if (recordDate) {
            getCashRecords(recordDate).then(function (records) {
                return setDraft({
                    recordDate: recordDate,
                    accounts: records.reduce(function (acc, item) {
                        acc[item.accountId] = item.amount;
                        return acc;
                    }, {})
                });
            });
        }
    }, [recordDate]);
    var updateDate = useCallback(function (_a) {
        var target = _a.target;
        setDraft(__assign(__assign({}, draft), { recordDate: collapseISODate(target.value) }));
    }, [draft]);
    var updateAccount = useCallback(function (_a) {
        var _b;
        var target = _a.target;
        setDraft(__assign(__assign({}, draft), { accounts: __assign(__assign({}, draft.accounts), (_b = {}, _b[target.name.split('_')[1]] = target.value, _b)) }));
    }, [draft]);
    var submitForm = useCallback(function () { return __awaiter(_this, void 0, void 0, function () {
        var balances;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    balances = Object.keys(draft.accounts)
                        .filter(function (key) { return draft.accounts[key] !== ''; }) //only save explicit zeroes as zero, unfilled fields should not be set
                        .map(function (key) { return ({
                        accountId: Number(key),
                        amount: Number(draft.accounts[key])
                    }); });
                    return [4 /*yield*/, saveCashRecords(draft.recordDate, balances)];
                case 1:
                    _a.sent();
                    onClose();
                    return [2 /*return*/];
            }
        });
    }); }, [draft, onClose]);
    return { accounts: accounts, draft: draft, updateAccount: updateAccount, updateDate: updateDate, submitForm: submitForm };
}
