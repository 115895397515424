var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import { ActionButton } from '@fluentui/react';
import * as React from 'react';
import styled from '@emotion/styled';
import { useHistory } from 'react-router-dom';
export var Header = function (_a) {
    var showNav = _a.showNav, onLogout = _a.onLogout;
    var history = useHistory();
    return (React.createElement(StyledAppHeader, null,
        React.createElement("span", null,
            React.createElement(ActionButton, { iconProps: { iconName: 'CollapseMenu' }, onClick: showNav }),
            React.createElement(ActionButton, { iconProps: { iconName: 'Home' }, onClick: function () { return history.push('/'); } })),
        React.createElement(ActionButton, { iconProps: { iconName: 'SignOut' }, text: 'Logout', onClick: onLogout })));
};
//TODO style this according to the theme, or use MUI's AppBar or something.
//or put the header in a left
var StyledAppHeader = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n    display: flex;\n    justify-content: space-between;\n    // background-color: black;\n    border-bottom: 1px solid gray;     \n"], ["\n    display: flex;\n    justify-content: space-between;\n    // background-color: black;\n    border-bottom: 1px solid gray;     \n"])));
var templateObject_1;
