import { currencySymbols, defaultCurrency } from '../currencies';
import * as React from 'react';
//TODO reuse styles/code from the FuelLogCard
export var FuelLogPreviewCard = function (_a) {
    var kilometersPerLiter = _a.kilometersPerLiter, kilometers = _a.kilometers, totalCost = _a.totalCost;
    return (React.createElement("div", { style: { display: 'flex', justifyContent: 'space-between', border: '1px solid gray', padding: 8, boxShadow: '0px 1px 14px 1px rgba(125,125,125,0.94)' } },
        React.createElement("div", { style: { minWidth: 100, paddingRight: 10, borderRight: '1px solid gray' } },
            React.createElement("span", { style: { fontSize: 'x-large', marginRight: 5, alignSelf: 'center' } }, (kilometersPerLiter === null || kilometersPerLiter === void 0 ? void 0 : kilometersPerLiter.toFixed(2)) || '--.--'),
            React.createElement("span", { style: { alignSelf: 'flex-end', fontSize: 'small' } }, "km/l")),
        React.createElement("div", null,
            React.createElement("div", { style: { textAlign: 'right' } },
                kilometers,
                " ",
                React.createElement("span", { style: { fontSize: 'smaller' } }, "km")),
            React.createElement("div", { style: { textAlign: 'right' } },
                totalCost.toFixed(2),
                React.createElement("span", { style: { fontSize: 'smaller' } },
                    " ",
                    currencySymbols[defaultCurrency.toLowerCase()])))));
};
