import * as React from 'react';
import { useCallback, useEffect, useState } from 'react';
import { commitPath, getGitStatus, getNotes, gitPull, gitPush, saveFileContent } from '../services/server-api';
import { IconButton, Label, Spinner } from '@fluentui/react';
import { useLocation } from 'react-router';
import { FileContent } from './file';
import { Directory as DirectoryViewer } from './directory';
import { Breadcrumbs } from './breadcrumbs';
import { Search } from './search';
import { downloadIcon, searchIcon, uploadIcon } from './icons';
import { RecentNotes } from './recent-notes';
import { Link } from 'react-router-dom';
import { todayAsISODate } from '../common/date.utils';
import { useLoadTodayDiary } from './todays-diary';
export var Notes = function () {
    var _a = useFileSystemItem(), fileSystemItem = _a.fileSystemItem, setFileSystemItem = _a.setFileSystemItem, refresh = _a.refresh;
    var _b = useState(false), showSearch = _b[0], setShowSearch = _b[1];
    var _c = useState(null), gitStatus = _c[0], setGitStatus = _c[1];
    useEffect(function () {
        getGitStatus().then(setGitStatus);
    }, [setGitStatus]);
    var pullGit = useCallback(function () {
        gitPull()
            .then(setGitStatus)
            .then(refresh);
    }, [setGitStatus, refresh]);
    var pushGit = useCallback(function () {
        gitPush()
            .then(setGitStatus)
            .then(refresh);
    }, [setGitStatus, refresh]);
    var onSaveContent = useCallback(function (content) {
        return saveFileContent(fileSystemItem.path, content).then(setFileSystemItem);
    }, [fileSystemItem === null || fileSystemItem === void 0 ? void 0 : fileSystemItem.path, setFileSystemItem]);
    var onCommit = useCallback(function () {
        //todo add support for specifying a commit message
        commitPath(fileSystemItem.path).then(setFileSystemItem);
    }, [fileSystemItem === null || fileSystemItem === void 0 ? void 0 : fileSystemItem.path, setFileSystemItem]);
    if (!fileSystemItem)
        return React.createElement(Spinner, null);
    var breadcrumbs = fileSystemItem.breadcrumbs, path = fileSystemItem.path, directoryContents = fileSystemItem.directoryContents, fileContent = fileSystemItem.fileContent, pendingCommit = fileSystemItem.pendingCommit, type = fileSystemItem.type, diaryInfo = fileSystemItem.diaryInfo;
    var parentDirectory = breadcrumbs.length > 2 ? breadcrumbs[breadcrumbs.length - 2].path : null;
    return (React.createElement(React.Fragment, null,
        React.createElement(Breadcrumbs, { breadcrumbs: breadcrumbs }),
        React.createElement("div", { style: { display: 'flex', justifyContent: 'space-between' } }, diaryInfo && type === 'F' &&
            React.createElement(DiaryPrevNext, { diaryInfo: diaryInfo, directory: parentDirectory })),
        React.createElement(IconButton, { iconProps: searchIcon, title: "Search", onClick: function () { return setShowSearch(true); } }),
        (gitStatus === null || gitStatus === void 0 ? void 0 : gitStatus.behind) ? React.createElement(IconButton, { iconProps: downloadIcon, title: "Git Pull", onClick: pullGit }) : null,
        (gitStatus === null || gitStatus === void 0 ? void 0 : gitStatus.ahead) ? React.createElement(IconButton, { iconProps: uploadIcon, title: "Git Push", onClick: pushGit }) : null,
        type === 'D' &&
            React.createElement(React.Fragment, null,
                React.createElement(DirectoryViewer, { path: path, contents: directoryContents, onCommit: onCommit, pendingCommit: pendingCommit }),
                React.createElement("hr", null),
                React.createElement(Label, null, "Recent Files"),
                React.createElement(RecentNotes, null)),
        type === 'F' && React.createElement(FileContent, { content: fileContent, onSaveContent: onSaveContent, pendingCommit: pendingCommit, onCommit: onCommit, path: path }),
        React.createElement(Search, { show: showSearch, onDismiss: function () { return setShowSearch(false); } })));
};
var DiaryPrevNext = function (_a) {
    var diaryInfo = _a.diaryInfo, directory = _a.directory;
    var loadTodayDiary = useLoadTodayDiary();
    var today = todayAsISODate(); //TODO consider memoizing? If there are #perf issues start here
    return (React.createElement(React.Fragment, null,
        diaryInfo.prev &&
            React.createElement(Link, { to: "/notes?path=".concat(encodeURIComponent("".concat(directory, "/").concat(diaryInfo.prev.name))) }, "<< ".concat(diaryInfo.prev.name)),
        diaryInfo.next &&
            React.createElement(Link, { to: "/notes?path=".concat(encodeURIComponent("".concat(directory, "/").concat(diaryInfo.next.name))) }, "".concat(diaryInfo.next.name, " >>")),
        !diaryInfo.next && today.toString() != diaryInfo.latest.name && // we're at the end and the latest (ie current) isn't already today's diary
            React.createElement("a", { href: "#", onClick: loadTodayDiary }, 'Today >>')));
};
function useFileSystemItem() {
    var _a = useState(null), fileSystemItem = _a[0], setFileSystemItem = _a[1];
    var location = useLocation();
    var path = getPath(location);
    var refresh = useCallback(function () {
        getNotes(path !== null && path !== void 0 ? path : '').then(setFileSystemItem);
    }, [path, setFileSystemItem]);
    useEffect(function () { return refresh(); }, [refresh]);
    return { fileSystemItem: fileSystemItem, setFileSystemItem: setFileSystemItem, refresh: refresh };
}
function getPath(loc) {
    try {
        return loc.search.substring(1).split('&').find(function (x) { return x.indexOf('path') === 0; }).split('=')[1];
    }
    catch (_a) {
        return null;
    }
}
