import { useEffect, useState } from 'react';
import { CategoryDropdown } from './payments/category-dropdown';
import { getSpendingByCategory } from '../services/server-api';
import { List } from './list';
import * as React from 'react';
export var HistoryAnalysis = function () {
    var _a = useState(null), selectedCategory = _a[0], setSelectedCategory = _a[1];
    var _b = useState(null), byCategoryData = _b[0], setByCategoryData = _b[1];
    useEffect(function () {
        if (selectedCategory) {
            getSpendingByCategory(selectedCategory).then(setByCategoryData);
        }
    }, [selectedCategory]);
    return (React.createElement(React.Fragment, null,
        React.createElement(CategoryDropdown, { value: selectedCategory, onChange: setSelectedCategory }),
        byCategoryData &&
            React.createElement(List, { data: byCategoryData, idField: 'id' })));
};
