var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { useCallback, useEffect, useRef, useState } from 'react';
import * as React from 'react';
import { getPayment, savePayment } from '../../services/server-api';
import { PrimaryButton, DefaultButton, Stack, TextField, Toggle, Spinner } from '@fluentui/react';
import { CategoryDropdown } from './category-dropdown';
import { currencyRadioOptions, defaultCurrency } from '../currencies';
import { horizontalChoiceGroup, stackTokens, StyledChoiceGroup } from '../styles';
import { CurrencyInput } from '../currency-input';
import { collapseISODate, expandISODate, todayAsISODate } from '../../common/date.utils';
export var PaymentForm = function (_a) {
    var onClose = _a.onClose, id = _a.id;
    var _b = usePaymentForm(onClose, id), payment = _b.payment, updatePayment = _b.updatePayment, updateCurrency = _b.updateCurrency, updateCategory = _b.updateCategory, submitForm = _b.submitForm, saveAndClose = _b.saveAndClose, showIncurredDates = _b.showIncurredDates, setShowIncurredDates = _b.setShowIncurredDates, showSplitFields = _b.showSplitFields, setShowSplitFields = _b.setShowSplitFields, formStartRef = _b.formStartRef;
    if (!payment)
        return React.createElement(Spinner, null);
    return (React.createElement("form", null,
        React.createElement(Stack, { tokens: stackTokens },
            React.createElement(Stack, { horizontal: true, tokens: stackTokens },
                React.createElement(TextField, { styles: { root: { flex: 1 } }, componentRef: formStartRef, label: 'Date', type: "date", onChange: updatePayment, value: payment.paidDate ? expandISODate(payment.paidDate) : undefined, name: "paidDate" }),
                React.createElement(Toggle, { label: 'Incurred', checked: showIncurredDates, onChange: function (_, val) { return setShowIncurredDates(val); } })),
            showIncurredDates &&
                React.createElement(React.Fragment, null,
                    React.createElement(TextField, { label: 'Incurred Begin', type: "date", onChange: updatePayment, value: payment.incurredBeginDate ? expandISODate(payment.incurredBeginDate) : undefined, name: "incurredBeginDate" }),
                    React.createElement(TextField, { label: 'Incurred End', type: "date", onChange: updatePayment, value: payment.incurredEndDate ? expandISODate(payment.incurredEndDate) : undefined, name: "incurredEndDate" })),
            React.createElement(TextField, { label: "Counterparty", name: "counterparty", value: payment.counterparty, onChange: updatePayment }),
            React.createElement(StyledChoiceGroup, { selectedKey: payment.currency, label: "Currency", styles: horizontalChoiceGroup, onChange: updateCurrency, options: currencyRadioOptions }),
            React.createElement(Stack, { horizontal: true, tokens: stackTokens },
                React.createElement(CurrencyInput, { amount: payment.amount, currency: payment.currency, name: "amount", onChange: updatePayment }),
                React.createElement(Toggle, { label: 'Split', checked: showSplitFields, onChange: function (_, val) { return setShowSplitFields(val); } })),
            showSplitFields &&
                React.createElement(CurrencyInput, { label: "Incurred Amount", amount: payment.incurredAmount, currency: payment.currency, name: "incurredAmount", onChange: updatePayment }),
            React.createElement(CategoryDropdown, { showLabel: true, value: payment.categoryId, onChange: updateCategory }),
            React.createElement(TextField, { label: "Notes", name: "note", value: payment.note, multiline: true, onChange: updatePayment }),
            React.createElement(Stack, { horizontal: true, tokens: stackTokens },
                React.createElement(PrimaryButton, { onClick: submitForm }, "Save"),
                !id &&
                    React.createElement(PrimaryButton, { onClick: saveAndClose }, "Save & Close"),
                React.createElement(DefaultButton, { onClick: onClose }, "Cancel")))));
};
function usePaymentForm(onClose, paymentId) {
    var _this = this;
    var _a = useState(false), showIncurredDates = _a[0], setShowIncurredDates = _a[1];
    var _b = useState(paymentId ? null : initializePayment()), payment = _b[0], setPayment = _b[1];
    var _c = useState(false), showSplitFields = _c[0], setShowSplitFields = _c[1];
    var formStartRef = useRef();
    useEffect(function () {
        if (paymentId) {
            getPayment(paymentId)
                .then(function (payment) {
                if (payment.incurredBeginDate || payment.incurredEndDate) {
                    setShowIncurredDates(true);
                }
                if (payment.incurredAmount !== null) {
                    setShowSplitFields(true);
                }
                return payment;
            })
                .then(setPayment);
            //TODO cleanup - if the panel is closed before the request finishes an error is thrown
        }
    }, [paymentId]);
    useEffect(function () {
        if (showSplitFields && payment.incurredAmount == null && payment.amount !== null) {
            setPayment(function (payment) { return (__assign(__assign({}, payment), { incurredAmount: payment.amount / 2 })); });
        }
        else if (!showSplitFields) {
            setPayment(function (payment) { return (__assign(__assign({}, payment), { incurredAmount: null })); });
        }
    }, [showSplitFields]); //eslint-disable-line react-hooks/exhaustive-deps
    var updateCurrency = useCallback(function (_, _a) {
        var key = _a.key;
        setPayment(__assign(__assign({}, payment), { currency: key }));
    }, [payment]);
    var updateCategory = useCallback(function (categoryId) {
        setPayment(__assign(__assign({}, payment), { categoryId: categoryId }));
    }, [payment]);
    var updatePayment = useCallback(function (_a) {
        var _b;
        var target = _a.target;
        setPayment(__assign(__assign({}, payment), (_b = {}, _b[target.name] = target.type == 'date' ? collapseISODate(target.value) : target.value, _b)));
    }, [payment]);
    var saveAndClose = useCallback(function () { return __awaiter(_this, void 0, void 0, function () {
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, savePayment(preparePayment(payment, showIncurredDates))];
                case 1:
                    _a.sent();
                    onClose();
                    return [2 /*return*/];
            }
        });
    }); }, [payment, onClose, showIncurredDates]);
    /**
     * submit changes its behavior depending on whether this is a new payment (form stays open, another payment is added)
     * or editing an existing payment (close the form).
     */
    var submitForm = useCallback(function () { return __awaiter(_this, void 0, void 0, function () {
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    if (!(payment.id !== -1)) return [3 /*break*/, 2];
                    return [4 /*yield*/, saveAndClose()];
                case 1:
                    _a.sent();
                    return [3 /*break*/, 4];
                case 2: return [4 /*yield*/, savePayment(preparePayment(payment, showIncurredDates))];
                case 3:
                    _a.sent();
                    setShowIncurredDates(false);
                    setShowSplitFields(false);
                    setPayment(function (payment) { return initializePayment(payment.paidDate); });
                    formStartRef.current.focus();
                    _a.label = 4;
                case 4: return [2 /*return*/];
            }
        });
    }); }, [payment, saveAndClose, showIncurredDates]);
    return {
        payment: payment,
        updatePayment: updatePayment,
        updateCategory: updateCategory,
        updateCurrency: updateCurrency,
        submitForm: submitForm,
        saveAndClose: saveAndClose,
        showIncurredDates: showIncurredDates,
        setShowIncurredDates: setShowIncurredDates,
        showSplitFields: showSplitFields,
        setShowSplitFields: setShowSplitFields,
        formStartRef: formStartRef
    };
}
var preparePayment = function (payment, useIncurredDates) { return (__assign(__assign({}, payment), { paidDate: payment.paidDate, incurredBeginDate: useIncurredDates ? payment.incurredBeginDate : null, incurredEndDate: useIncurredDates ? payment.incurredEndDate : null })); };
function initializePayment(paidDate) {
    if (paidDate === void 0) { paidDate = todayAsISODate(); }
    return {
        id: -1,
        paidDate: paidDate,
        incurredBeginDate: paidDate,
        incurredEndDate: paidDate,
        categoryId: -1,
        amount: null,
        incurredAmount: null,
        counterparty: '',
        currency: defaultCurrency,
        note: ''
    };
}
