import * as React from 'react';
import { useEffect, useState, useCallback } from 'react';
import { getCashAssetAllocationHistory } from '../../services/server-api';
import { List } from '../list';
export var CashAllocationHistory = function (_a) {
    var id = _a.id;
    var _b = useState(null), allocationHistory = _b[0], setAllocationHistory = _b[1];
    useEffect(function () {
        getCashAssetAllocationHistory(id).then(setAllocationHistory);
    }, [id]);
    var columnsSelector = useCallback(function (columnName) { return columnName != 'allocationCode'; }, []);
    return (allocationHistory &&
        React.createElement(List, { columnsSelector: columnsSelector, data: allocationHistory }));
};
