var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import { useCallback, useEffect, useState } from 'react';
import { getLoggedIn, login, logout } from '../services/server-api';
import styled from '@emotion/styled';
import { DefaultButton, Spinner, TextField } from '@fluentui/react';
import * as React from 'react';
function LoginForm(_a) {
    var onLoggedIn = _a.onLoggedIn;
    var _b = useState(''), password = _b[0], setPassword = _b[1];
    var onSubmit = useCallback(function (e) {
        e.preventDefault(); //prevent page reload on form submission
        login(password).then(onLoggedIn);
    }, [password, onLoggedIn]);
    var onInputChange = useCallback(function (event) { return setPassword(event.target.value); }, []);
    return (React.createElement(StyledForm, null,
        React.createElement(TextField, { value: password, onChange: onInputChange, type: "password", onSubmit: onSubmit }),
        React.createElement(DefaultButton, { onClick: onSubmit }, "Login")));
}
var StyledForm = styled.form(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n    display: flex;\n    justify-content: center;\n    height: 100vh;\n    align-items: center;\n    flex-direction: column;\n    \n    > *:not(:last-child) {\n      margin-bottom: 8px;\n    }\n  \n    > * {\n      height: 32px;\n      width: 100%;\n      max-width: 400px;\n    }\n"], ["\n    display: flex;\n    justify-content: center;\n    height: 100vh;\n    align-items: center;\n    flex-direction: column;\n    \n    > *:not(:last-child) {\n      margin-bottom: 8px;\n    }\n  \n    > * {\n      height: 32px;\n      width: 100%;\n      max-width: 400px;\n    }\n"])));
function useLoginState() {
    //TODO put a logout function on the context and use it elsewhere
    //TODO any 401 anywhere should flip the loggedIn state to false
    var _a = useState(false), loggedIn = _a[0], setLoggedIn = _a[1];
    var _b = useState(true), loading = _b[0], setLoading = _b[1];
    useEffect(function () {
        setLoading(true);
        getLoggedIn().then(function (res) {
            setLoading(false);
            setLoggedIn(res);
        });
    }, []);
    var onLogout = useCallback(function () {
        logout().then(function (success) { return setLoggedIn(success ? false : loggedIn); });
    }, [loggedIn]);
    return { loggedIn: loggedIn, setLoggedIn: setLoggedIn, onLogout: onLogout, loading: loading };
}
export function withLogin(WrappedComponent) {
    return function Component() {
        var _a = useLoginState(), loggedIn = _a.loggedIn, setLoggedIn = _a.setLoggedIn, onLogout = _a.onLogout, loading = _a.loading;
        if (loading) {
            return React.createElement(Spinner, { styles: spinnerStyles });
        }
        return loggedIn ?
            React.createElement(WrappedComponent, { onLogout: onLogout }) :
            React.createElement(LoginForm, { onLoggedIn: setLoggedIn });
    };
}
var spinnerStyles = { root: { height: '100vh' } };
var templateObject_1;
