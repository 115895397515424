var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import * as React from 'react';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { getPayments } from '../../services/server-api';
import { CommandBar, Panel } from '@fluentui/react';
import { List, SortDirection } from '../list';
import { PaymentForm } from './payment-form';
import { commandBarStyles } from '../styles';
import { useBoolean } from '@fluentui/react-hooks';
export var Payments = function () {
    var _a = useSortablePayments(), payments = _a.payments, sortConfig = _a.sortConfig, onSortPayments = _a.onSortPayments, reloadData = _a.reloadData;
    var _b = useBoolean(false), addPayment = _b[0], _c = _b[1], showAddPayment = _c.setTrue, hideAddPayment = _c.setFalse;
    var commands = useCommandBarCommands(showAddPayment, reloadData);
    var _d = useState(null), selectedItem = _d[0], setSelectedItem = _d[1];
    var hideEditPayment = function () { return setSelectedItem(null); };
    return (React.createElement(React.Fragment, null,
        React.createElement(CommandBar, { items: commands, styles: commandBarStyles }),
        payments &&
            React.createElement(List, { data: payments, onClick: function (item) { return setSelectedItem(item.id); }, sortConfig: sortConfig, sortData: onSortPayments, searchableTextFields: textFields, idField: 'id' }),
        React.createElement(Panel, { isOpen: addPayment, headerText: "Add Payment", isBlocking: false, onDismiss: hideAddPayment },
            React.createElement(PaymentForm, { onClose: hideAddPayment })),
        React.createElement(Panel, { isOpen: !!selectedItem, headerText: "Edit Payment", isBlocking: false, onDismiss: hideEditPayment },
            React.createElement(PaymentForm, { onClose: hideEditPayment, id: selectedItem }))));
};
/*
Much of this can be generalized for use outside of payments,
the sorting logic could be part of the table and the reload
is only necessary because I don't have a store
 */
function useSortablePayments() {
    var _a = useState(), payments = _a[0], setPayments = _a[1];
    var _b = useState(defaultPaymentsSortConfig), sortConfig = _b[0], setSortConfig = _b[1];
    var onSortPayments = useCallback(function (config) {
        setSortConfig(config);
    }, []);
    var reloadData = useCallback(function () {
        getPayments()
            .then(function (data) { return sortPayments(data, sortConfig); })
            .then(setPayments);
    }, [sortConfig]);
    useEffect(function () {
        if (payments && sortConfig) {
            setPayments(sortPayments(payments, sortConfig));
        }
        //payments is NOT a dependency of this effect or we'll get an infinite loop
        //the only way payments is modified outside of sorting is on load/refresh
        //and the sorting is done there separately
    }, [sortConfig]); //eslint-disable-line react-hooks/exhaustive-deps
    useEffect(function () {
        getPayments()
            .then(function (data) { return sortPayments(data, defaultPaymentsSortConfig); })
            .then(setPayments);
    }, []);
    return { payments: payments, sortConfig: sortConfig, onSortPayments: onSortPayments, reloadData: reloadData };
}
var sortPayments = function (payments, sortConfig) {
    var newPayments = __spreadArray([], payments, true);
    var sortFn = sortConfig.direction === SortDirection.asc ?
        function (a, b) { return a[sortConfig.fieldName] > b[sortConfig.fieldName] ? 1 : -1; } :
        function (a, b) { return a[sortConfig.fieldName] < b[sortConfig.fieldName] ? 1 : -1; };
    newPayments.sort(sortFn);
    return newPayments;
};
function useCommandBarCommands(onAddPayment, reloadData) {
    var commands = useMemo(function () { return ([
        {
            split: true,
            key: 'newPayment',
            text: 'Payment',
            iconProps: { iconName: 'Add' },
            onClick: onAddPayment,
            // subMenuProps: {
            //     items: [
            //         {
            //             key: 'newIncome',
            //             text: 'Income',
            //             onClick: onAddIncome,
            //             iconProps: { iconName: 'Money' },
            //         },
            //         {
            //             key: 'newCashAssets',
            //             text: 'Cash balances',
            //             onClick: onAddCar,
            //             iconProps: { iconName: 'AddToShoppingList' },
            //         },
            //     ]
            // },
        },
        {
            key: 'refresh',
            text: 'Refresh',
            iconProps: { iconName: 'Refresh' },
            onClick: reloadData
        }
    ]); }, [reloadData, onAddPayment]);
    return commands;
}
var textFields = ['counterparty', 'note', 'categoryName'];
var defaultPaymentsSortConfig = { fieldName: 'paidDate', direction: SortDirection.desc };
