import * as React from 'react';
import { Link } from 'react-router-dom';
export var Breadcrumbs = function (_a) {
    var breadcrumbs = _a.breadcrumbs;
    return (React.createElement("h3", null, breadcrumbs.map(function (breadcrumb, i) {
        return React.createElement("span", { key: breadcrumb.name },
            React.createElement(Link, { to: "/notes/?path=".concat(encodeURIComponent(breadcrumb.path)) }, breadcrumb.name),
            i !== breadcrumbs.length - 1 && ' /');
    })));
};
