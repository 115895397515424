import { useCallback, useEffect, useMemo, useState } from 'react';
import { getCarSummary, getCashAllocations, getUnreportedSpending } from '../../services/server-api';
import { List } from '../list';
import * as React from 'react';
import { useBoolean } from '@fluentui/react-hooks';
import { CommandBar, Panel, PanelType, Stack } from '@fluentui/react';
import { commandBarStyles } from '../styles';
import { AddCashRecord } from './add-cash-record';
import { expandISODate } from '../../common/date.utils';
import { AddCashAssetAllocation } from './add-cash-asset-allocation';
import { CashAllocationHistory } from './cash-allocation-history';
export var CashRecordHistory = function () {
    var _a = useState([]), carSummary = _a[0], setCarSummary = _a[1];
    var _b = useState(null), cashAllocations = _b[0], setCashAllocations = _b[1];
    var _c = useState(null), unreportedSpending = _c[0], setUnreportedSpending = _c[1];
    var reloadData = useCallback(function () {
        getCarSummary().then(setCarSummary);
        getCashAllocations().then(function (data) { return setCashAllocations(data); });
        getUnreportedSpending().then(setUnreportedSpending);
    }, []);
    useEffect(function () { reloadData(); }, [reloadData]);
    var _d = useBoolean(false), addCar = _d[0], _e = _d[1], showAddCar = _e.setTrue, hideAddCar = _e.setFalse;
    var _f = useBoolean(false), addCaa = _f[0], _g = _f[1], showAddCaa = _g.setTrue, hideAddCaa = _g.setFalse;
    var commands = useCommandBarCommands(showAddCar, showAddCaa, reloadData);
    var _h = useState(null), selectedItem = _h[0], setSelectedItem = _h[1];
    var hideEditCar = function () { return setSelectedItem(null); };
    var _j = useState(null), selectedAllocation = _j[0], setSelectedAllocation = _j[1];
    var hideViewAllocationHistory = function () { return setSelectedAllocation(null); };
    return (React.createElement(React.Fragment, null,
        React.createElement(CommandBar, { items: commands, styles: commandBarStyles }),
        React.createElement(Stack, null,
            cashAllocations &&
                React.createElement(React.Fragment, null,
                    React.createElement(List, { data: cashAllocations, onClick: function (alloc) { return setSelectedAllocation(alloc.allocationCode == 'Unallocated' ? null : alloc.allocationCode); }, idField: 'allocationCode' })),
            carSummary &&
                React.createElement(List, { data: carSummary, onClick: function (car) { return setSelectedItem(car.recordDate); }, idField: 'recordDate' }),
            unreportedSpending &&
                React.createElement(List, { data: unreportedSpending, idField: 'endDate' })),
        React.createElement(Panel, { isOpen: addCar, headerText: "Add Cash Assets", onDismiss: hideAddCar },
            React.createElement(AddCashRecord, { onClose: hideAddCar })),
        React.createElement(Panel, { isOpen: addCaa, headerText: "Update Cash Allocations", onDismiss: hideAddCaa },
            React.createElement(AddCashAssetAllocation, { onClose: hideAddCaa })),
        React.createElement(Panel, { type: PanelType.large, isOpen: !!selectedAllocation, headerText: selectedAllocation, onDismiss: hideViewAllocationHistory },
            React.createElement(CashAllocationHistory, { id: selectedAllocation, onClose: hideViewAllocationHistory })),
        React.createElement(Panel, { isOpen: !!selectedItem, headerText: selectedItem && "Edit ".concat((expandISODate(selectedItem))), onDismiss: hideEditCar },
            React.createElement(AddCashRecord, { onClose: function () { return setSelectedItem(null); }, id: selectedItem }))));
};
function useCommandBarCommands(onAddCar, onAddCaa, reloadData) {
    var commands = useMemo(function () { return ([
        {
            split: true,
            key: 'newCar',
            text: 'Cash Assets',
            iconProps: { iconName: 'Add' },
            onClick: onAddCar,
        },
        {
            split: true,
            key: 'newCaa',
            text: 'Update Allocations',
            iconProps: { iconName: 'Edit' },
            onClick: onAddCaa,
        },
        {
            key: 'refresh',
            text: 'Refresh',
            iconProps: { iconName: 'Refresh' },
            onClick: reloadData
        }
    ]); }, [reloadData, onAddCar, onAddCaa]);
    return commands;
}
