import { List } from '../list';
import { useCallback, useEffect, useMemo, useState } from 'react';
import * as React from 'react';
import { getAllIncome } from '../../services/server-api';
import { CommandBar, Panel } from '@fluentui/react';
import { useBoolean } from '@fluentui/react-hooks';
import { commandBarStyles } from '../styles';
import { IncomeForm } from './income-form';
export var Income = function () {
    var _a = useState([]), income = _a[0], setIncome = _a[1];
    var reloadData = useCallback(function () { return getAllIncome().then(setIncome); }, []);
    useEffect(function () { reloadData(); }, [reloadData]);
    var _b = useBoolean(false), addIncome = _b[0], _c = _b[1], showAddIncome = _c.setTrue, hideAddIncome = _c.setFalse;
    var commands = useCommandBarCommands(showAddIncome, reloadData);
    var _d = useState(null), selectedItem = _d[0], setSelectedItem = _d[1];
    var hideEditIncome = function () { return setSelectedItem(null); };
    return (React.createElement(React.Fragment, null,
        React.createElement(CommandBar, { items: commands, styles: commandBarStyles }),
        income &&
            React.createElement(List, { data: income, onClick: function (income) { return setSelectedItem(income.id); }, idField: 'income_id' }),
        React.createElement(Panel, { isOpen: addIncome, headerText: "Add Income", onDismiss: hideAddIncome },
            React.createElement(IncomeForm, { onClose: hideAddIncome })),
        React.createElement(Panel, { isOpen: !!selectedItem, headerText: "Edit Income", onDismiss: hideEditIncome },
            React.createElement(IncomeForm, { onClose: hideEditIncome, id: selectedItem }))));
};
function useCommandBarCommands(onAddIncome, reloadData) {
    var commands = useMemo(function () { return ([
        {
            split: true,
            key: 'newIncome',
            text: 'Income',
            iconProps: { iconName: 'Add' },
            onClick: onAddIncome,
        },
        {
            key: 'refresh',
            text: 'Refresh',
            iconProps: { iconName: 'Refresh' },
            onClick: reloadData
        }
    ]); }, [reloadData, onAddIncome]);
    return commands;
}
