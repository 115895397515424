var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import config from '../config/config';
var handleResponse = function (res) {
    if (res.status === 401) {
        throw 'Auth failure';
    }
    else if (!res.ok) {
        throw 'Fetch failed';
    }
    else {
        return res;
    }
};
export var logout = function () {
    return fetch("".concat(config.apiServiceUrl, "/logout"))
        .then(handleResponse)
        .then(function () { return true; });
};
export var login = function (password) {
    return fetch("".concat(config.apiServiceUrl, "/login"), {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        credentials: 'include',
        body: JSON.stringify({ password: password })
    })
        .then(handleResponse)
        .then(function () { return true; });
};
export var getLoggedIn = function () { return get('login').catch(function () { return false; }); };
export var getPayment = function (paymentId) { return get("payments/".concat(paymentId)); };
export var getPayments = function () { return get('payments'); };
export var getCarSummary = function () { return get('car/summary'); };
export var getActiveCashAccounts = function () { return get('car/accounts'); };
export var saveCashRecords = function (recordDate, accountBalances) {
    return put('car/records', {
        recordDate: recordDate,
        accountBalances: accountBalances
    });
};
export var getCashRecords = function (recordDate) { return get("car/records?recordDate=".concat(recordDate)); };
export var getCashAllocations = function () {
    return get('cash-allocations');
};
export var getCashAssetAllocationHistory = function (allocationCode) {
    return get("cash-allocations/history/".concat(allocationCode));
};
export var getUnreportedSpending = function () {
    return get('unreported-spending');
};
export var getAllIncome = function () {
    return get('income');
};
export var getIncome = function (incomeId) {
    return get("income/".concat(incomeId));
};
export var saveIncome = function (income) {
    return income.id === -1 ? post('income', income) : put('income', income);
};
export var getFxHistory = function () {
    return get('fx-history');
};
export var getFxTransaction = function (transactionId) {
    return get("fx-transactions/".concat(transactionId));
};
export var saveFxTransaction = function (fxTransaction) {
    return fxTransaction.id === -1 ? post('fx-transactions', fxTransaction) : put('fx-transactions', fxTransaction);
};
export var getAllStocks = function () {
    return get('stock-transactions');
};
export var getStockHoldingsSummary = function () {
    return get('stock-holdings-summary');
};
export var getStockTransaction = function (transactionId) {
    return get("stock-transactions/".concat(transactionId));
};
export var saveStockTransaction = function (stockTransaction) {
    return stockTransaction.id === -1 ? post('stock-transactions', stockTransaction) : put('stock-transactions', stockTransaction);
};
export var getStockAccounts = function () {
    return get('/stock-accounts');
};
export var getStockAccountCashBalances = function () {
    return get('stock-accounts/cash-balances');
};
export var getStockAccountCashFlow = function (accountId) {
    return get("stock-accounts/".concat(accountId, "/cash-flow"));
};
export var saveTransferCashToStockAccount = function (dto) {
    return post('compound-transactions/cash-stock-accounts-fund-transfer', dto);
};
export var getStockAccountCashTransaction = function (id) {
    return get("stock-accounts/cash-transactions/".concat(id));
};
export var saveStockAccountCashTransaction = function (transaction) {
    return put("stock-accounts/cash-transactions/".concat(transaction.id), transaction);
};
export var getAllCategories = function () {
    return get('categories');
};
export var savePayment = function (payment) {
    return payment.id === -1 ? post('payments', payment) : put('payments', payment);
};
export var saveAllocationRecord = function (allocation) {
    return post('cash-allocations', allocation);
};
export function getSpendingByCategory(rootCategoryId) {
    return get("analysis/spending-by-category?rootCategoryId=".concat(rootCategoryId));
}
export var getFuelLog = function (pageSize) {
    return get("fuel-log".concat(pageSize ? "?pageSize=".concat(pageSize) : ''));
};
export var saveFuelLog = function (fuelLog) {
    return post('fuel-log', fuelLog);
};
export var getNotes = function (path) {
    return get("notes/path?path=".concat(encodeURIComponent(path)));
};
export var createItem = function (path, name, type) {
    return post("notes/path?type=".concat(type, "&path=").concat(encodeURIComponent(path), "&name=").concat(name));
};
export var saveFileContent = function (path, content) {
    return put("notes/files?&path=".concat(encodeURIComponent(path)), content, false);
};
export var commitPath = function (path) {
    return put("notes/commit?&path=".concat(encodeURIComponent(path)));
};
export var gitPull = function () {
    return put('notes/pull');
};
export var gitPush = function () {
    return put('notes/push');
};
export var getGitStatus = function () { return get('notes/status'); };
export var searchNotes = function (query, excludeHidden) {
    if (excludeHidden === void 0) { excludeHidden = true; }
    return get("notes/search?query=".concat(encodeURIComponent(query), "&excludeHidden=").concat(excludeHidden));
};
export var getRecentFiles = function () { return get('notes/recent'); };
export var renameDirectoryItem = function (from, to) {
    return post("notes/move?from=".concat(encodeURIComponent(from), "&to=").concat(encodeURIComponent(to)));
};
export var getDiaryPath = function (date, createIfNotExists) {
    if (createIfNotExists === void 0) { createIfNotExists = false; }
    return get("notes/diary-path?date=".concat(date, "&createIfNotExists=").concat(createIfNotExists))
        .then(function (res) { return res.diaryPath; });
};
// const debugSleep = (ms) => (...args) => new Promise(resolve => setTimeout(resolve, ms, args))
var get = function (path) {
    return fetch("".concat(config.apiServiceUrl, "/").concat(path), { credentials: 'include' })
        .then(handleResponse)
        .then(function (res) { return res.json(); });
};
var put = function (path, body, json) {
    if (json === void 0) { json = true; }
    return fetch("".concat(config.apiServiceUrl, "/").concat(path), {
        method: 'PUT',
        headers: json && body ? {
            'Content-Type': 'application/json'
        } : undefined,
        credentials: 'include',
        body: body && json ? JSON.stringify(body) : body
    })
        .then(handleResponse)
        .then(function (res) { return __awaiter(void 0, void 0, void 0, function () {
        var text;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, res.text()];
                case 1:
                    text = _a.sent();
                    try {
                        return [2 /*return*/, JSON.parse(text)];
                    }
                    catch (_b) {
                        return [2 /*return*/, text];
                    }
                    return [2 /*return*/];
            }
        });
    }); });
};
var post = function (path, body) { return fetch("".concat(config.apiServiceUrl, "/").concat(path), {
    method: 'POST',
    headers: {
        'Content-Type': 'application/json'
    },
    credentials: 'include',
    body: body ? JSON.stringify(body) : null
})
    .then(handleResponse)
    .then(function (res) { return __awaiter(void 0, void 0, void 0, function () {
    var text;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0: return [4 /*yield*/, res.text()];
            case 1:
                text = _a.sent();
                try {
                    return [2 /*return*/, JSON.parse(text)];
                }
                catch (_b) {
                    return [2 /*return*/, text];
                }
                return [2 /*return*/];
        }
    });
}); }); };
