var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import { useEffect, useState } from 'react';
import { getRecentFiles } from '../services/server-api';
import * as React from 'react';
import { Link } from 'react-router-dom';
import { Icon } from '@fluentui/react';
import { fileIconName } from './icons';
import styled from '@emotion/styled';
function useRecentNotes() {
    var _a = useState(null), recentNotes = _a[0], setRecentNotes = _a[1];
    useEffect(function () {
        getRecentFiles().then(setRecentNotes);
    }, []);
    return recentNotes;
}
export var RecentNotes = function () {
    var recentNotes = useRecentNotes();
    return (React.createElement(React.Fragment, null, recentNotes === null || recentNotes === void 0 ? void 0 : recentNotes.map(function (path) {
        return React.createElement(StyledLine, { key: path },
            React.createElement(Icon, { iconName: fileIconName, styles: iconStyles }),
            React.createElement(Link, { to: "/notes/?path=".concat(encodeURIComponent(path)) }, path));
    })));
};
var StyledLine = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  line-height: 1.2em;\n  font-size: 1.1em;\n  display: flex;\n  word-break: break-word;\n"], ["\n  line-height: 1.2em;\n  font-size: 1.1em;\n  display: flex;\n  word-break: break-word;\n"])));
var iconStyles = { root: { marginRight: 4, transform: 'translateY(0.2em)' } };
var templateObject_1;
