import { useCallback } from 'react';
import { getDiaryPath } from '../services/server-api';
import { useHistory } from 'react-router-dom';
import { todayAsISODate } from '../common/date.utils';
export var useLoadTodayDiary = function () {
    var history = useHistory();
    return useCallback(function () {
        var today = todayAsISODate();
        getDiaryPath(today, true)
            .then(function (path) { return history.push("/notes?path=".concat(encodeURIComponent(path))); });
    }, [history]);
};
