import * as React from 'react';
import { Nav } from '@fluentui/react';
import { SwipeableDrawer } from '@material-ui/core';
import { Route, Switch } from 'react-router-dom';
import { CashRecordHistory } from '../financials/cash/cash-record-history';
import { FuelHistory } from '../financials/fuel-log/fuel-log';
import { HistoryAnalysis } from '../financials/history-analysis';
import { Notes } from '../notes/notes.index';
import { Home } from '../home/home.index';
import { Income } from '../financials/income/income';
import { Payments } from '../financials/payments/payments';
import { Stocks } from '../financials/stocks/stocks';
import { StockAccountsCash } from '../financials/stocks/stock-accounts-cash';
import { Fx } from '../financials/fx/fx';
export var Navigation = function (_a) {
    var hideNav = _a.hideNav, showNav = _a.showNav, shouldShowNav = _a.shouldShowNav;
    return (React.createElement(React.Fragment, null,
        React.createElement(SwipeableDrawer, { onClose: hideNav, onOpen: showNav, open: shouldShowNav },
            React.createElement(Nav, { groups: navGroups, onLinkClick: hideNav, styles: navStyles })),
        React.createElement(Switch, null,
            React.createElement(Route, { path: "/spending", component: Payments }),
            React.createElement(Route, { path: "/income", component: Income }),
            React.createElement(Route, { path: "/stocks", component: Stocks }),
            React.createElement(Route, { path: "/stock-accounts", component: StockAccountsCash }),
            React.createElement(Route, { path: "/fx", component: Fx }),
            React.createElement(Route, { path: "/cash", component: CashRecordHistory }),
            React.createElement(Route, { path: "/fuel", component: FuelHistory }),
            React.createElement(Route, { path: "/analysis", component: HistoryAnalysis }),
            React.createElement(Route, { path: "/notes", component: Notes }),
            React.createElement(Route, { path: "/", component: Home }))));
};
var navGroups = [
    {
        name: '',
        links: [
            {
                key: 'home',
                name: 'Home',
                url: '#/'
            },
            {
                key: 'notes',
                name: 'Notes',
                url: '#/notes'
            }
        ]
    },
    {
        name: 'Financials',
        links: [
            {
                key: 'spending',
                name: 'Spending',
                url: '#/spending'
            },
            {
                key: 'income',
                name: 'Income',
                url: '#/income'
            },
            {
                key: 'stocks',
                name: 'Stocks',
                url: '#/stocks'
            },
            {
                key: 'stock-accounts',
                name: 'Stock Accounts',
                url: '#/stock-accounts'
            },
            {
                key: 'fx',
                name: 'FX Transactions',
                url: '#/fx'
            },
            {
                key: 'cash',
                name: 'Cash',
                url: '#/cash'
            },
            {
                key: 'fuel',
                name: 'Fuel Log',
                url: '#/fuel'
            },
            {
                key: 'analysis',
                name: 'Analysis',
                url: '#/analysis'
            }
        ]
    }
];
var navStyles = {
    root: {
        minWidth: 300
    },
    groupContent: {
        marginBottom: 0
    }
};
