import * as React from 'react';
import { useEffect, useMemo, useState } from 'react';
import { ComboBox } from '@fluentui/react';
import { getStockAccounts } from '../../services/server-api';
export var StockAccountDropdown = function (_a) {
    var value = _a.value, onChange = _a.onChange, _b = _a.label, label = _b === void 0 ? 'Account' : _b;
    var _c = useState(), accounts = _c[0], setAccounts = _c[1];
    useEffect(function () {
        getStockAccounts().then(setAccounts);
    }, []);
    var options = useMemo(function () { return accounts === null || accounts === void 0 ? void 0 : accounts.map(function (account) { return ({
        key: account.id,
        text: account.name,
        data: account
    }); }); }, [accounts]);
    return (React.createElement(ComboBox, { autoComplete: "on", placeholder: "Select account", label: label, selectedKey: value, onChange: function (ev, option) { return onChange(option.key); }, onRenderOption: function (option) { return React.createElement(React.Fragment, null, option.data.name); }, options: options }));
};
