import { List } from '../list';
import { useCallback, useEffect, useMemo, useState } from 'react';
import * as React from 'react';
import { getStockAccountCashBalances, getStockAccountCashFlow } from '../../services/server-api';
import { CommandBar, Panel } from '@fluentui/react';
import { useBoolean } from '@fluentui/react-hooks';
import { commandBarStyles } from '../styles';
import { StocksForm } from './stocks-form';
import { FxForm } from '../fx/fx-form';
import { CashStockAccountFundsTransferForm } from './cash-stock-account-funds-transform-form';
import { StockAccountCashTransactionForm } from './stock-account-cash-form';
export var StockAccountsCash = function () {
    var _a = useState([]), stockAccountsCashBalances = _a[0], setStockAccountsCashBalances = _a[1];
    var _b = useState([]), stockAccountCashFlow = _b[0], setStockAccountCashFlow = _b[1];
    var _c = useState(null), stockAccountId = _c[0], setStockAccountId = _c[1];
    var reloadData = useCallback(function () {
        getStockAccountCashBalances().then(setStockAccountsCashBalances);
    }, []);
    useEffect(function () { reloadData(); }, [reloadData]);
    useEffect(function () {
        if (stockAccountId) {
            getStockAccountCashFlow(stockAccountId).then(setStockAccountCashFlow);
        }
        else {
            setStockAccountCashFlow([]);
        }
    }, [stockAccountId]);
    var _d = useBoolean(false), transferFunds = _d[0], _e = _d[1], showTransferFunds = _e.setTrue, hideTransferFunds = _e.setFalse;
    var commands = useCommandBarCommands(showTransferFunds, reloadData);
    var _f = useState(null), selectedItem = _f[0], setSelectedItem = _f[1]; //TODO type
    var hideEditItem = function () { return setSelectedItem(null); };
    return (React.createElement(React.Fragment, null,
        React.createElement(CommandBar, { items: commands, styles: commandBarStyles }),
        stockAccountsCashBalances &&
            React.createElement(List, { onClick: function (account) { return setStockAccountId(account.id); }, data: stockAccountsCashBalances }),
        /* TODO add id field for performance, but it needs to be a compound field.*/
        stockAccountCashFlow &&
            React.createElement(List, { data: stockAccountCashFlow, onClick: function (_a) {
                    var recordType = _a.recordType, recordId = _a.recordId;
                    return setSelectedItem({ recordId: recordId, recordType: recordType });
                } }),
        React.createElement(Panel, { isOpen: selectedItem && selectedItem.recordType == 'stock_transaction', headerText: "Edit Stock Transaction", onDismiss: hideEditItem },
            React.createElement(StocksForm, { onClose: hideEditItem, id: selectedItem && selectedItem.recordId })),
        React.createElement(Panel, { isOpen: selectedItem && selectedItem.recordType == 'fx_transaction', headerText: "Edit FX Transaction", onDismiss: hideEditItem },
            React.createElement(FxForm, { onClose: hideEditItem, id: selectedItem && selectedItem.recordId })),
        React.createElement(Panel, { isOpen: selectedItem && selectedItem.recordType === 'cash_flow', headerText: "Edit Stock Account Cash Transaction", onDismiss: hideEditItem },
            React.createElement(StockAccountCashTransactionForm, { onClose: hideEditItem, id: selectedItem && selectedItem.recordId })),
        React.createElement(Panel, { isOpen: transferFunds, headerText: "Transfer Cash", onDismiss: hideTransferFunds },
            React.createElement(CashStockAccountFundsTransferForm, { onClose: hideTransferFunds }))));
};
function useCommandBarCommands(onTransferFunds, reloadData) {
    var commands = useMemo(function () { return ([
        {
            split: true,
            key: 'newCashStockAccountFundsTransfer',
            text: 'Transfer Funds',
            iconProps: { iconName: 'Add' },
            onClick: onTransferFunds,
        },
        {
            key: 'refresh',
            text: 'Refresh',
            iconProps: { iconName: 'Refresh' },
            onClick: reloadData
        }
    ]); }, [reloadData, onTransferFunds]);
    return commands;
}
