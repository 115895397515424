import { useCallback, useState } from 'react';
import _debounce from 'lodash/debounce';
export function useDebouncedInput(initialValue) {
    var _a = useState(initialValue), inputVal = _a[0], setInputVal = _a[1];
    var _b = useState(initialValue), debouncedValue = _b[0], setDebouncedValue = _b[1];
    var updateDebouncedValue = useCallback(_debounce(setDebouncedValue, 300), [setDebouncedValue]); //eslint-disable-line react-hooks/exhaustive-deps
    var updateValue = useCallback(function (val) {
        setInputVal(val);
        updateDebouncedValue(val);
    }, [setInputVal, updateDebouncedValue]);
    return { inputVal: inputVal, debouncedValue: debouncedValue, updateValue: updateValue };
}
