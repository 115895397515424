import * as React from 'react';
import { render } from 'react-dom';
import { initializeIcons } from '@uifabric/icons';
import { withLogin } from './common/with-login.hoc';
import { useBoolean } from '@fluentui/react-hooks';
import { HashRouter as Router } from 'react-router-dom';
import { Header } from './header/header';
import { Navigation } from './header/navigation';
initializeIcons();
var App = function (_a) {
    var onLogout = _a.onLogout;
    var _b = useBoolean(false), shouldShowNav = _b[0], _c = _b[1], showNav = _c.setTrue, hideNav = _c.setFalse;
    return (React.createElement(React.Fragment, null,
        React.createElement(Router, null,
            React.createElement(Header, { showNav: showNav, onLogout: onLogout }),
            React.createElement(Navigation, { showNav: showNav, shouldShowNav: shouldShowNav, hideNav: hideNav }))));
};
var AppWithLogin = withLogin(App);
render(React.createElement(AppWithLogin, null), document.getElementById('app'));
