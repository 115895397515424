var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import { useDebouncedInput } from '../common/debounced-input.hook';
import * as React from 'react';
import { useEffect, useState } from 'react';
import { searchNotes } from '../services/server-api';
import { Icon, Stack, Panel, SearchBox, Toggle, FocusZone } from '@fluentui/react';
import ReactMarkdown from 'react-markdown';
import { Link } from 'react-router-dom';
import styled from '@emotion/styled';
import { NeutralColors, CommunicationColors } from '@fluentui/theme';
import { fileIconName } from './icons';
export var Search = function (_a) {
    var show = _a.show, onDismiss = _a.onDismiss;
    var _b = useDebouncedInput(''), inputVal = _b.inputVal, debouncedValue = _b.debouncedValue, updateValue = _b.updateValue;
    var _c = useState(true), excludeHidden = _c[0], setExcludeHidden = _c[1];
    var _d = useState(null), searchResults = _d[0], setSearchResults = _d[1];
    useEffect(function () {
        if ((debouncedValue === null || debouncedValue === void 0 ? void 0 : debouncedValue.length) < 3) {
            setSearchResults(null);
            return;
        }
        searchNotes(debouncedValue, excludeHidden).then(setSearchResults);
    }, [debouncedValue, excludeHidden]);
    return (React.createElement(Panel, { isOpen: show, onDismiss: onDismiss, isHiddenOnDismiss: true, isBlocking: false, onRenderHeader: function () {
            return (React.createElement(Stack, { styles: headerStackStyles },
                React.createElement(SearchBox, { value: inputVal, onChange: function (_, val) { return updateValue(val); } }),
                React.createElement(Toggle, { checked: !excludeHidden, onChange: function (_, val) { return setExcludeHidden(!val); }, label: 'Include hidden' })));
        } },
        React.createElement(FocusZone, null, searchResults === null || searchResults === void 0 ? void 0 :
            searchResults.names.map(function (item) { return React.createElement(DirectoryItemSearchResult, { key: item.path, item: item, onDismiss: onDismiss }); }), searchResults === null || searchResults === void 0 ? void 0 :
            searchResults.contents.map(function (result) {
                return React.createElement(StyledSearchContentResult, { key: "".concat(result.path), to: "/notes/?path=".concat(encodeURIComponent(result.path)), onClick: onDismiss },
                    React.createElement("div", { className: "search-result-card" },
                        React.createElement(StyledSearchCardHeader, null, result.path),
                        React.createElement(StyledSearchCardBody, null, result.items.map(function (item, i) {
                            return React.createElement("div", { key: item.lineNumber },
                                React.createElement(ReactMarkdown, null, item.text),
                                i !== result.items.length - 1 && React.createElement("hr", null));
                        }))));
            }))));
};
var headerStackStyles = {
    root: {
        flexGrow: 1,
        marginLeft: 24 //hack - needs to match the panel content padding
    }
};
var DirectoryItemSearchResult = function (_a) {
    var item = _a.item, onDismiss = _a.onDismiss;
    return (React.createElement(StyledSearchResult, { to: "/notes/?path=".concat(encodeURIComponent(item.path)), onClick: onDismiss },
        React.createElement("div", { className: "search-result-card" },
            React.createElement(Icon, { iconName: fileIconName }),
            " ",
            item.fileName)));
};
var CARD_SPACING = '6px';
var hoverBoxShadow = "8px 4px 6px -3px ".concat(NeutralColors.gray120);
var StyledSearchResult = styled(Link)(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  text-decoration: none;\n\n  div.search-result-card {\n    border: 1px solid ", ";\n    margin-bottom: ", ";\n    overflow-wrap: anywhere;\n    box-shadow: 4px 2px 6px -3px ", ";\n    border-radius: 4px;  \n    color: ", ";\n    padding: ", ";\n    \n    :hover {\n      box-shadow: ", ";\n      transform: scale(1.02);\n    }\n  }\n  \n  :focus > div.search-result-card {\n    box-shadow: ", ";\n    transform: scale(1.02);\n  }\n"], ["\n  text-decoration: none;\n\n  div.search-result-card {\n    border: 1px solid ", ";\n    margin-bottom: ", ";\n    overflow-wrap: anywhere;\n    box-shadow: 4px 2px 6px -3px ", ";\n    border-radius: 4px;  \n    color: ", ";\n    padding: ", ";\n    \n    :hover {\n      box-shadow: ", ";\n      transform: scale(1.02);\n    }\n  }\n  \n  :focus > div.search-result-card {\n    box-shadow: ", ";\n    transform: scale(1.02);\n  }\n"])), NeutralColors.gray120, CARD_SPACING, NeutralColors.gray120, NeutralColors.black, CARD_SPACING, hoverBoxShadow, hoverBoxShadow);
var StyledSearchCardHeader = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  padding: ", ";\n  background: ", ";\n  border-bottom: 1px solid ", ";\n"], ["\n  padding: ", ";\n  background: ", ";\n  border-bottom: 1px solid ", ";\n"])), CARD_SPACING, CommunicationColors.tint40, NeutralColors.gray120);
var StyledSearchCardBody = styled.div(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  padding: ", ";\n"], ["\n  padding: ", ";\n"])), CARD_SPACING);
var StyledSearchContentResult = styled(StyledSearchResult)(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  padding-bottom: 0;\n  \n  div.search-result-card {\n    padding: 0;\n  }\n"], ["\n  padding-bottom: 0;\n  \n  div.search-result-card {\n    padding: 0;\n  }\n"])));
var templateObject_1, templateObject_2, templateObject_3, templateObject_4;
