var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import { IconButton } from '@fluentui/react';
import ReactMarkdown from 'react-markdown';
import * as React from 'react';
import remarkGfm from 'remark-gfm';
import styled from '@emotion/styled';
import { commitIcon, editIcon } from './icons';
export var FileViewer = function (_a) {
    var content = _a.content, onEdit = _a.onEdit, pendingCommit = _a.pendingCommit, onCommit = _a.onCommit, path = _a.path;
    return (React.createElement(React.Fragment, null,
        pendingCommit && React.createElement(IconButton, { iconProps: commitIcon, onClick: onCommit, title: "Commit" }),
        React.createElement(IconButton, { iconProps: editIcon, onClick: onEdit, title: "Edit" }),
        React.createElement(StyledReactMarkdown, { remarkPlugins: [remarkGfm], transformLinkUri: customUriHandler(path) }, content)));
};
var StyledReactMarkdown = styled(ReactMarkdown)(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  ul.contains-task-list {\n    padding-left: 1em;\n    \n    li {\n      list-style: none;  \n    }\n  }\n"], ["\n  ul.contains-task-list {\n    padding-left: 1em;\n    \n    li {\n      list-style: none;  \n    }\n  }\n"])));
var customUriHandler = function (path) { return function (uri) {
    if (uri.indexOf('./') === 0) {
        //this whole thing is pretty hacky. works in the common case of a relative link to a file in the same directory
        //anything else doesn't
        path = "./".concat(path);
        var directory = path.substr(0, path.lastIndexOf('/'));
        return "#/notes?path=".concat(directory.substring(2), "/").concat(uri.substring(2));
    }
    return uri;
}; };
var templateObject_1;
