var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import styled from '@emotion/styled';
import { ChoiceGroup } from '@fluentui/react';
export var commandBarStyles = { root: { padding: 0 } };
export var horizontalChoiceGroup = { flexContainer: { display: 'flex' } };
export var stackTokens = { childrenGap: 8 };
export var StyledChoiceGroup = styled(ChoiceGroup)(templateObject_1 || (templateObject_1 = __makeTemplateObject(["  \n  & label {\n    margin-right: 8px;\n  }\n"], ["  \n  & label {\n    margin-right: 8px;\n  }\n"])));
var templateObject_1;
