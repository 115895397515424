var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { useCallback, useEffect, useState } from 'react';
import * as React from 'react';
import { getFxTransaction, saveFxTransaction } from '../../services/server-api';
import { PrimaryButton, DefaultButton, Stack, TextField } from '@fluentui/react';
import { stackTokens } from '../styles';
import { CurrencyInput } from '../currency-input';
import { StockAccountDropdown } from '../stocks/stock-account-dropdown';
import { formatDay, formatTimeInput } from '../../common/date.utils';
export var FxForm = function (_a) {
    var onClose = _a.onClose, id = _a.id;
    var _b = useFxTransactionForm(onClose, id), fxTransaction = _b.fxTransaction, updateTransaction = _b.updateTransaction, updateAccount = _b.updateAccount, submitForm = _b.submitForm;
    if (!fxTransaction)
        return null; //TODO spinner
    return (React.createElement("form", null,
        React.createElement(Stack, { tokens: stackTokens },
            React.createElement(TextField, { label: 'Date', type: "date", onChange: updateTransaction, value: fxTransaction.transactionDate, name: "transactionDate" }),
            React.createElement(TextField, { label: 'Time', type: "time", step: "0.001", onChange: updateTransaction, value: fxTransaction.transactionTime, name: "transactionTime" }),
            React.createElement(StockAccountDropdown, { value: fxTransaction.accountId, onChange: updateAccount }),
            React.createElement(Stack.Item, null,
                React.createElement(CurrencyInput, { amount: fxTransaction.usdAmount, label: "USD", currency: 'USD', name: "usdAmount", onChange: updateTransaction })),
            React.createElement(Stack.Item, null,
                React.createElement(CurrencyInput, { amount: fxTransaction.ilsAmount, label: "ILS", currency: 'ILS', name: "ilsAmount", onChange: updateTransaction })),
            React.createElement(Stack.Item, null,
                React.createElement(CurrencyInput, { label: "Commission", amount: fxTransaction.usdCommission, currency: 'USD', name: "usdCommission", onChange: updateTransaction })),
            React.createElement(TextField, { label: "Notes", name: "note", value: fxTransaction.note, multiline: true, onChange: updateTransaction }),
            React.createElement(Stack, { horizontal: true, tokens: stackTokens },
                React.createElement(PrimaryButton, { onClick: submitForm }, "Save"),
                React.createElement(DefaultButton, { onClick: onClose }, "Cancel")))));
};
function useFxTransactionForm(onClose, fxTransactionId) {
    var _this = this;
    var _a = useState(fxTransactionId ? null : initializeTransaction()), fxTransaction = _a[0], setFxTransaction = _a[1];
    useEffect(function () {
        if (fxTransactionId) {
            getFxTransaction(fxTransactionId)
                .then(function (transaction) { return (__assign(__assign({}, transaction), { transactionDate: formatDay(transaction.transactionDate), transactionTime: formatTimeInput(transaction.transactionDate) })); })
                .then(setFxTransaction);
        }
    }, [fxTransactionId]);
    var updateTransaction = useCallback(function (_a) {
        var _b;
        var target = _a.target;
        setFxTransaction(__assign(__assign({}, fxTransaction), (_b = {}, _b[target.name] = target.value, _b)));
    }, [fxTransaction]);
    var updateAccount = useCallback(function (accountId) {
        setFxTransaction(__assign(__assign({}, fxTransaction), { accountId: accountId }));
    }, [fxTransaction]);
    var submitForm = useCallback(function () { return __awaiter(_this, void 0, void 0, function () {
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, saveFxTransaction(convertToTransactionDto(fxTransaction))];
                case 1:
                    _a.sent();
                    if (fxTransaction.id === -1) {
                        setFxTransaction(initializeTransaction());
                    }
                    else {
                        onClose();
                    }
                    return [2 /*return*/];
            }
        });
    }); }, [fxTransaction, onClose]);
    return { fxTransaction: fxTransaction, updateTransaction: updateTransaction, updateAccount: updateAccount, submitForm: submitForm };
}
function initializeTransaction() {
    var today = formatDay(new Date());
    return {
        id: -1,
        accountId: null,
        transactionDate: today,
        transactionTime: '',
        ilsAmount: null,
        usdAmount: null,
        usdCommission: null,
        note: ''
    };
}
function convertToTransactionDto(transaction) {
    return __assign(__assign({}, transaction), { transactionDate: "".concat(transaction.transactionDate, "T").concat(transaction.transactionTime || '00:00:00', "Z") });
}
