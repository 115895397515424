var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { useCallback, useEffect, useRef, useState } from 'react';
import { IconButton } from '@fluentui/react';
import Editor from '@monaco-editor/react';
import * as React from 'react';
import AceEditor from 'react-ace';
import { Prompt } from 'react-router';
import { saveAndCloseIcon, saveIcon, toggleEditorIcon, wrapLinesIcon } from './icons';
import 'ace-builds/src-noconflict/mode-markdown';
import 'ace-builds/src-noconflict/theme-tomorrow_night_eighties';
import 'ace-builds/src-min-noconflict/ext-searchbox';
//see more themes here: https://ace.c9.io/build/kitchen-sink.html
//more options here: https://ace.c9.io/build/kitchen-sink.html //TODO - search
/***
 * Represents the content saved on the server per path.
 * TODO - LRU this cache. In can grow to the size of the notes repo, which currently isn't that big so this isn't urgent
 */
var savedContents = new Map();
export var FileEditor = function (_a) {
    var content = _a.content, onSaveContent = _a.onSaveContent, onExitEditor = _a.onExitEditor, path = _a.path;
    var _b = useState(false), saving = _b[0], setSaving = _b[1];
    var _c = useState(true), wordWrap = _c[0], setWordWrap = _c[1];
    //TODO automatically use ace on mobile, or configure ace to be as nice as monaco somehow
    var _d = useState('ace'), editor = _d[0], setEditor = _d[1];
    var editorRef = useRef(null);
    var _e = useState(false), isEditorDirty = _e[0], setIsEditorDirty = _e[1];
    var onEditorMount = function (editor) { return editorRef.current = editor; };
    //TODO this whole thing is a mess of race conditions. If I introduce a state management system
    //this could be simplified and then the bugs can be eliminated.
    //One risk is that onSaveContent refers to a specific path, and if the editor content is not in sync with the path the onSaveContent
    //will save to, and a save is triggered - data will be lost.
    useEffect(function () {
        //after saving the contents the content updates, after switching paths the path updates
        //regardless, keep track of what content the server has for each path
        savedContents.set(path, content);
    }, [path, content]);
    useEffect(function () {
        var _a, _b;
        //if the path changes (user switched files while the editor is open), retrieve the value of the contents so that the editor can be updated
        //from the map set in the previous useEffect. This assumes the previous one will happen first, which is a bad assumption
        //the alternative is to take the content from the props, assuming they both arrive at the same time (I'm not sure about that)
        //but content CANNOT be a dependency of this effect or the saved contents will be inserted into the editor whenever the content is saved,
        //causing the cursor to jump in middle of typing and potentially losing the content entered during the time the save was being executed
        if (editor === 'monaco') {
            return (_a = editorRef.current) === null || _a === void 0 ? void 0 : _a.getModel().setValue(savedContents.get(path));
        }
        else {
            return (_b = editorRef.current) === null || _b === void 0 ? void 0 : _b.editor.getSession().setValue(savedContents.get(path));
        }
    }, [path, editor]);
    var getEditorValue = useCallback(function () {
        var _a, _b;
        if (editor === 'monaco') {
            return (_a = editorRef.current) === null || _a === void 0 ? void 0 : _a.getValue();
        }
        else {
            return (_b = editorRef.current) === null || _b === void 0 ? void 0 : _b.editor.getValue();
        }
    }, [editor]);
    var saveContent = useCallback(function () { return __awaiter(void 0, void 0, void 0, function () {
        var draft;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    draft = getEditorValue();
                    if (!(content !== draft)) return [3 /*break*/, 2];
                    setSaving(true);
                    return [4 /*yield*/, onSaveContent(draft).then(function () {
                            //if the user is typing, an onchange will recalculate this anyway. But if not,
                            //it's necessary to compare the current value to what was saved. draft is definitely the most reliable value
                            setIsEditorDirty(getEditorValue() !== draft);
                            setSaving(false);
                        })];
                case 1:
                    _a.sent();
                    _a.label = 2;
                case 2: return [2 /*return*/];
            }
        });
    }); }, [onSaveContent, setSaving, content, getEditorValue]);
    var onExit = function () { return __awaiter(void 0, void 0, void 0, function () {
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, saveContent()];
                case 1:
                    _a.sent();
                    onExitEditor();
                    return [2 /*return*/];
            }
        });
    }); };
    useEffect(function () {
        //this hook only controls navigation outside the page. react-router navigation is covered by the Prompt component
        if (!isEditorDirty) {
            return;
        }
        window.onbeforeunload = function (e) {
            e.preventDefault();
            e.returnValue = '';
        };
        return function () {
            window.onbeforeunload = null;
        };
    }, [isEditorDirty]);
    useEffect(function () {
        var interval = setInterval(function () { return saveContent(); }, 1000 * 30);
        return function () { return clearInterval(interval); };
    }, [saveContent]);
    return (React.createElement(React.Fragment, null,
        React.createElement(IconButton, { title: 'Save and close', iconProps: saveAndCloseIcon, onClick: onExit }),
        React.createElement(IconButton, { disabled: !isEditorDirty || saving, title: 'Save', iconProps: saveIcon, onClick: saveContent }),
        React.createElement(IconButton, { title: "Wrap lines", checked: wordWrap, toggle: true, onClick: function () { return setWordWrap(function (val) { return !val; }); }, iconProps: wrapLinesIcon }),
        React.createElement(IconButton, { title: "Toggle editor type", checked: editor === 'monaco', toggle: true, onClick: function () { return setEditor(function (val) { return val === 'ace' ? 'monaco' : 'ace'; }); }, iconProps: toggleEditorIcon }),
        //this is for in-app navigation with react-router
        //for browser navigation the onbeforeunload is setup in a hook
        React.createElement(Prompt, { when: isEditorDirty, message: 'You have unsaved changes, are you sure you want to leave?' }),
        editor === 'monaco' ?
            React.createElement(Editor, { options: { wordWrap: wordWrap ? 'on' : 'off' }, defaultLanguage: "markdown", height: '80vh', defaultValue: content, onChange: function (val) { return setIsEditorDirty(val !== content); }, onMount: onEditorMount }) :
            React.createElement(AceEditor, { ref: editorRef, width: '100%', fontSize: 14, mode: "markdown", scrollMargin: [8], focus: true, highlightActiveLine: true, wrapEnabled: wordWrap, tabSize: 2, theme: "tomorrow_night_eighties", showPrintMargin: false, name: "file-editor", defaultValue: content, onChange: function (val) { return setIsEditorDirty(val !== content); }, editorProps: { $blockScrolling: true }, commands: [
                    {
                        name: 'save',
                        bindKey: { win: 'Ctrl-s', mac: 'Command-s' },
                        exec: saveContent
                    },
                    {
                        name: 'saveandclose',
                        bindKey: { win: 'Ctrl-Enter', mac: 'Command-Enter' },
                        exec: onExit
                    },
                ] })));
};
