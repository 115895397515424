import * as React from 'react';
import { useMemo, useState } from 'react';
import { createItem, renameDirectoryItem } from '../services/server-api';
import { Icon, IconButton, Stack, TextField } from '@fluentui/react';
import { horizontalChoiceGroup, StyledChoiceGroup } from '../financials/styles';
import { Link, useHistory } from 'react-router-dom';
import { addIcon, cancelIcon, checkIcon, commitIcon, downloadIconName, editIcon, externalIconName, fileIconName, folderIconName, hideIcon, saveIcon } from './icons';
export var Directory = function (_a) {
    var path = _a.path, contents = _a.contents, onCommit = _a.onCommit, pendingCommit = _a.pendingCommit;
    var _b = useState(false), showHidden = _b[0], setShowHidden = _b[1];
    var filteredContents = useMemo(function () {
        //HACK - this should be optional, but when it matters, more often than not reversed is more useful than the order on disk
        var results = contents.reverse();
        if (!showHidden) {
            return results.filter(function (i) { return i.name[0] !== '.'; });
        }
        return results;
    }, [contents, showHidden]);
    return (React.createElement(React.Fragment, null,
        React.createElement(DirectoryCommandBar, { currentDirectory: path, onCommit: onCommit, pendingCommit: pendingCommit, showHidden: showHidden, setShowHidden: setShowHidden }),
        React.createElement(DirectoryContents, { directory: path, contents: filteredContents })));
};
var DirectoryCommandBar = function (_a) {
    var currentDirectory = _a.currentDirectory, onCommit = _a.onCommit, pendingCommit = _a.pendingCommit, showHidden = _a.showHidden, setShowHidden = _a.setShowHidden;
    var _b = useState(false), showNewItemForm = _b[0], setShowNewItemForm = _b[1];
    var _c = useState('F'), newItemType = _c[0], setNewItemType = _c[1];
    var _d = useState(''), name = _d[0], setName = _d[1];
    var history = useHistory();
    var saveFile = function () {
        createItem(currentDirectory, name, newItemType)
            .then(function () { return setShowNewItemForm(false); })
            .then(function () { return history.push("/notes?path=".concat(encodeURIComponent("".concat(currentDirectory, "/").concat(name)))); });
    };
    return (React.createElement(React.Fragment, null,
        pendingCommit && React.createElement(IconButton, { title: "Commit", iconProps: commitIcon, onClick: onCommit }),
        React.createElement(IconButton, { iconProps: hideIcon, toggle: true, checked: showHidden, title: "Show hidden folders", onClick: function () { return setShowHidden(function (val) { return !val; }); } }),
        React.createElement(IconButton, { iconProps: addIcon, onClick: function () { return setShowNewItemForm(true); } }),
        showNewItemForm &&
            React.createElement(Stack, { horizontal: true },
                React.createElement(TextField, { placeholder: 'Name', value: name, onChange: function (_, val) { return setName(val); } }),
                React.createElement(StyledChoiceGroup, { selectedKey: newItemType, styles: horizontalChoiceGroup, onChange: function (_, val) { return setNewItemType(val.key); }, options: itemTypeRadioOptions }),
                React.createElement(IconButton, { iconProps: checkIcon, disabled: !name, onClick: saveFile, title: "Create" }),
                React.createElement(IconButton, { iconProps: cancelIcon, onClick: function () { return setShowNewItemForm(false); }, title: "Cancel" }))));
};
var DirectoryContents = function (_a) {
    var directory = _a.directory, contents = _a.contents;
    return (React.createElement("table", null,
        React.createElement("tbody", null, contents.map(function (item) { return React.createElement(DirectoryItem, { directory: directory, item: item, key: item.name }); }))));
};
var DirectoryItem = function (_a) {
    var directory = _a.directory, item = _a.item;
    var _b = useState(false), editMode = _b[0], setEditMode = _b[1];
    var _c = useState(item.name), itemNameDraft = _c[0], setItemNameDraft = _c[1];
    var onSave = function () {
        renameDirectoryItem("".concat(directory, "/").concat(item.name), "".concat(directory, "/").concat(itemNameDraft))
            .then(function () { return setEditMode(false); }); //TODO refresh view
    };
    return (React.createElement("tr", null,
        React.createElement("td", null,
            React.createElement(Icon, { iconName: item.isDirectory ? folderIconName : fileIconName })),
        React.createElement("td", null, item.pendingCommit ? '*' : ' '),
        editMode ?
            React.createElement("td", null,
                React.createElement(TextField, { placeholder: item.name, value: itemNameDraft, onChange: function (_, val) { return setItemNameDraft(val); } })) :
            item.isPlainText ?
                React.createElement("td", null,
                    React.createElement(Link, { to: "/notes?path=".concat(encodeURIComponent("".concat(directory, "/").concat(item.name))) }, item.name)) :
                React.createElement("td", null, item.name),
        React.createElement("td", null,
            React.createElement("a", { href: "/api/notes/download?path=".concat(directory, "/").concat(item.name), download: item.name },
                React.createElement(Icon, { iconName: downloadIconName, styles: iconStyles }))),
        React.createElement("td", null, item.openInBrowser &&
            React.createElement("a", { href: "/api/notes/viewfile?path=".concat(directory, "/").concat(item.name) },
                React.createElement(Icon, { iconName: externalIconName, styles: iconStyles }))),
        React.createElement("td", null, !editMode ?
            React.createElement(IconButton, { iconProps: editIcon, styles: iconButtonStyles, onClick: function () { return setEditMode(true); } }) :
            React.createElement(React.Fragment, null,
                React.createElement(IconButton, { iconProps: saveIcon, styles: iconButtonStyles, onClick: onSave }),
                React.createElement(IconButton, { iconProps: cancelIcon, styles: iconButtonStyles, onClick: function () { return setEditMode(false); } })))));
};
var iconStyles = { root: { fontSize: 16 } };
var iconButtonStyles = {
    root: {
        height: 16,
        width: 16
    }
};
var itemTypeRadioOptions = [{ key: 'F', text: 'File' }, { key: 'D', text: 'Directory' }];
