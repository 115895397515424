var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import { currencySymbols } from '../currencies';
import * as React from 'react';
import { FontIcon, Link } from '@fluentui/react';
import styled from '@emotion/styled';
import { formatDay, formatTime, isMidnight } from '../../common/date.utils';
//TODO finish extracting the inline styles to styled component,s reuse the margin/spacing sizes and colors
export var FuelLogCard = function (_a) {
    var _b;
    var fuelLog = _a.fuelLog, onViewPayment = _a.onViewPayment;
    return (React.createElement(StyledCard, null,
        React.createElement("div", { style: { borderBottom: '1px solid #ccc', marginBottom: 4 } },
            React.createElement("span", null, formatDay(fuelLog.timestamp)),
            React.createElement("span", { style: { marginLeft: 4, fontSize: 'smaller' } }, !isMidnight(fuelLog.timestamp) && formatTime(fuelLog.timestamp))),
        React.createElement("div", { style: { display: 'flex', justifyContent: 'space-between' } },
            React.createElement(StyledCardColumn, null,
                React.createElement("span", { style: { fontSize: 'x-large', marginRight: 4, alignSelf: 'center' } }, (_b = fuelLog.kilometersPerLiter) === null || _b === void 0 ? void 0 : _b.toFixed(2)),
                React.createElement("span", { style: { alignSelf: 'flex-end', fontSize: 'small' } }, "km/l")),
            React.createElement(StyledCardColumn, null,
                React.createElement(CardValue, { value: fuelLog.odometer, iconName: 'Clock', suffix: 'km' }),
                React.createElement(CardValue, { value: fuelLog.liters, iconName: 'DropShapeSolid', suffix: 'l' })),
            React.createElement("div", null,
                React.createElement(CardValue, { value: fuelLog.kilometers, suffix: 'km' }),
                React.createElement(CardValue, { value: fuelLog.totalCost.toFixed(2), suffix: currencySymbols[fuelLog.currency.toLowerCase()], onClick: onViewPayment }))),
        React.createElement(StyledNote, null, fuelLog.note)));
};
var StyledCard = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  max-width: 400px; //TODO instead of this, render a DetailsList for large screens and cards in small screens\n  border: 1px solid #cccccc;\n  padding: 8px;\n  margin-top: 4px;\n  box-shadow: 0 1px 12px 1px #ccc;\n"], ["\n  max-width: 400px; //TODO instead of this, render a DetailsList for large screens and cards in small screens\n  border: 1px solid #cccccc;\n  padding: 8px;\n  margin-top: 4px;\n  box-shadow: 0 1px 12px 1px #ccc;\n"])));
var StyledCardColumn = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  padding-right: 8px;\n  border-right: 1px solid #cccccc;\n"], ["\n  padding-right: 8px;\n  border-right: 1px solid #cccccc;\n"])));
var StyledCardValue = styled.div(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  text-align: right;\n"], ["\n  text-align: right;\n"])));
var StyledSmaller = styled.span(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  font-size: smaller;\n"], ["\n  font-size: smaller;\n"])));
var StyledNote = styled(StyledSmaller)(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n  font-style: italic;\n"], ["\n  font-style: italic;\n"])));
var CardValue = function (_a) {
    var value = _a.value, _b = _a.iconName, iconName = _b === void 0 ? null : _b, _c = _a.suffix, suffix = _c === void 0 ? null : _c, _d = _a.onClick, onClick = _d === void 0 ? null : _d;
    return (React.createElement(StyledCardValue, null,
        iconName && React.createElement(StyledSmaller, null,
            React.createElement(FontIcon, { iconName: iconName }),
            " "),
        onClick ?
            React.createElement(Link, { onClick: onClick }, value) :
            value,
        suffix && React.createElement(StyledSmaller, null,
            " ",
            suffix)));
};
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5;
