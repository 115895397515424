var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import * as React from 'react';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { CheckboxVisibility, DetailsList, DetailsListLayoutMode, IconButton, Label, SearchBox, SelectionMode, Stack, TextField } from '@fluentui/react';
import { currencies, currencyFields, currencySymbols } from './currencies';
import styled from '@emotion/styled';
import { useDebouncedInput } from '../common/debounced-input.hook';
import { collapseISODate, expandISODate, isoDateToFullDisplay } from '../common/date.utils';
import { CurrencyInput } from './currency-input';
import { stackTokens } from './styles';
import { cancelIcon, hideIcon } from '../notes/icons';
var idFields = ['id', 'categoryId', 'recordId'];
var fieldIsNotId = function (fieldName) { return !idFields.includes(fieldName); };
var formatFieldName = function (fieldName) {
    if (currencies.includes(fieldName.toUpperCase())) {
        return fieldName.toUpperCase();
    }
    var spaces = fieldName
        .split('_').join(' ') //underscores to spaces
        .replace(/([a-z])([A-Z])/g, '$1 $2'); //camel case to spaces
    return spaces.split(' ')
        .map(function (word) {
        return currencies.includes(word.toUpperCase()) ?
            word.toUpperCase() :
            word.charAt(0).toUpperCase() + word.slice(1);
    })
        .join(' ');
};
// Counts characters in a displayed number
// Note: doesn't include the displayed thousands separators. In any case the font
// isn't monospace so this just gets an estimate of the displayed width
var countDigits = function (value) {
    return Math.floor(Math.log10(Math.abs(value) < 1 ? 1 : Math.abs(value))) + 1 + //Leading zeroes are displayed when values are between -1 and 1
        (value < 0 ? 1 : 0) + //the minus sign
        3;
}; //decimal point plus the two digits after the decimal
var CurrencyField = function (_a) {
    var value = _a.value, currencyCode = _a.currencyCode, maxDigits = _a.maxDigits;
    if (!value && value !== 0)
        return null;
    return (React.createElement(StyledCurrency, null,
        React.createElement("span", { style: { position: 'absolute', marginRight: (maxDigits * 6) + 10 } }, currencySymbols[currencyCode]),
        React.createElement("span", null, value.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }))));
};
var StyledCurrency = styled.span(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  display: flex;\n  justify-content: flex-end;\n"], ["\n  display: flex;\n  justify-content: flex-end;\n"])));
function isDateColumn(key) {
    return key.toLowerCase().includes('date') || key.toLowerCase() === 'timestamp';
}
function getColumnRenderer(key, data) {
    var maxSize = 1;
    if (currencyFields.has(key)) {
        data.forEach(function (row) {
            maxSize = Math.max(maxSize, countDigits(row[key]));
        });
    }
    var columnRenderer = function (value) { return isDateColumn(key) ?
        isoDateToFullDisplay(value[key]) :
        currencyFields.has(key) ?
            React.createElement(CurrencyField, { value: value[key], currencyCode: currencyFields.get(key), maxDigits: maxSize }) :
            value[key]; };
    return columnRenderer;
}
export function List(_a) {
    var data = _a.data, sortConfig = _a.sortConfig, sortData = _a.sortData, idField = _a.idField, searchableTextFields = _a.searchableTextFields, columnsSelector = _a.columnsSelector, onClick = _a.onClick;
    var _b = useState(null), filterMenuColumn = _b[0], setFilterMenuColumn = _b[1];
    var _c = useState({}), columnFilters = _c[0], setColumnFilters = _c[1];
    var columns = useMemo(function () {
        if (!data.length)
            return [];
        var keys = Object.keys(data[0]);
        return keys.filter(function (key) { return columnsSelector ? columnsSelector(key) : fieldIsNotId(key); }).map(function (key) { return ({
            key: key,
            isFiltered: !!columnFilters[key],
            minWidth: currencies.includes(key.toUpperCase()) ? 100 : 150,
            maxWidth: currencies.includes(key.toUpperCase()) ? 100 : null,
            fieldName: key,
            onRender: getColumnRenderer(key, data),
            name: formatFieldName(key),
            styles: currencyFields.has(key) ? {
                root: {
                    textAlign: 'right',
                    width: '100%',
                },
                cellName: {
                    width: '100%'
                }
            } : null,
            isResizable: true,
            isSorted: (sortConfig === null || sortConfig === void 0 ? void 0 : sortConfig.fieldName) === key,
            isSortedDescending: (sortConfig === null || sortConfig === void 0 ? void 0 : sortConfig.direction) === SortDirection.desc,
            onColumnContextMenu: function (column) { return setFilterMenuColumn(column); },
            // isCollapsible: true, //what does this do?
            onColumnClick: function () {
                var newSort = {
                    fieldName: key,
                    direction: (sortConfig === null || sortConfig === void 0 ? void 0 : sortConfig.fieldName) === key ?
                        Number(!(sortConfig === null || sortConfig === void 0 ? void 0 : sortConfig.direction)) :
                        defaultSortDirection(key)
                };
                sortData(newSort);
            }
        }); });
    }, [data, sortConfig, sortData, columnFilters, columnsSelector]);
    var _d = useDebouncedInput(''), inputVal = _d.inputVal, debouncedValue = _d.debouncedValue, updateValue = _d.updateValue;
    var filteredData = useMemo(function () {
        return debouncedValue || Object.keys(columnFilters).length ?
            filterRows(data, debouncedValue, searchableTextFields, columnFilters) :
            data;
    }, [data, debouncedValue, columnFilters, searchableTextFields]);
    var getKey = useCallback(function (item) { return item[idField]; }, [idField]);
    return (React.createElement(React.Fragment, null,
        searchableTextFields &&
            React.createElement(SearchBox, { styles: searchStyles, underlined: true, placeholder: 'Search', onChange: function (val) { return updateValue(val.target.value); }, onClear: function () { return updateValue(''); }, value: inputVal }),
        filterMenuColumn &&
            React.createElement(FilterMenu, { column: filterMenuColumn, columnFilters: columnFilters, setColumnFilters: setColumnFilters, setFilterMenuColumn: setFilterMenuColumn }),
        !!filteredData.length &&
            React.createElement(DetailsList, { checkboxVisibility: CheckboxVisibility.hidden, items: filteredData, columns: columns, getKey: getKey, compact: true, onActiveItemChanged: onClick, layoutMode: DetailsListLayoutMode.fixedColumns, selectionMode: onClick ? SelectionMode.single : SelectionMode.none })));
}
var CurrencyFilterMenu = function (_a) {
    var column = _a.column, columnFilters = _a.columnFilters, setColumnFilters = _a.setColumnFilters;
    var filter = columnFilters[column.key];
    var _b = useDebouncedInput(filter === null || filter === void 0 ? void 0 : filter.min), minInputVal = _b.inputVal, debouncedMinValue = _b.debouncedValue, updateMinValue = _b.updateValue;
    useEffect(function () {
        setColumnFilters(function (prev) {
            var _a;
            return (__assign(__assign({}, prev), (_a = {}, _a[column.key] = __assign(__assign({}, prev[column.key]), { min: debouncedMinValue }), _a)));
        });
    }, [column, debouncedMinValue, setColumnFilters]);
    var _c = useDebouncedInput(filter === null || filter === void 0 ? void 0 : filter.max), maxInputVal = _c.inputVal, debouncedMaxValue = _c.debouncedValue, updateMaxValue = _c.updateValue;
    useEffect(function () {
        setColumnFilters(function (prev) {
            var _a;
            return (__assign(__assign({}, prev), (_a = {}, _a[column.key] = __assign(__assign({}, prev[column.key]), { max: debouncedMaxValue }), _a)));
        });
    }, [debouncedMaxValue, column, setColumnFilters]);
    return (React.createElement(React.Fragment, null,
        React.createElement(CurrencyInput, { label: "Minimum", amount: minInputVal, currency: column.key, onChange: function (_, value) { return updateMinValue(value); } }),
        React.createElement(CurrencyInput, { label: "Maximum", amount: maxInputVal, currency: column.key, onChange: function (_, value) { return updateMaxValue(value); } })));
};
var FilterMenu = function (props) {
    var column = props.column, columnFilters = props.columnFilters, setColumnFilters = props.setColumnFilters, setFilterMenuColumn = props.setFilterMenuColumn;
    var filterInputs;
    if (isDateColumn(column.key)) {
        var filter = columnFilters[column.key];
        filterInputs = (React.createElement(React.Fragment, null,
            React.createElement(TextField, { label: "Start", type: 'Date', value: (filter === null || filter === void 0 ? void 0 : filter.start) ? expandISODate(filter === null || filter === void 0 ? void 0 : filter.start) : undefined, onChange: function (_, value) {
                    setColumnFilters(function (prev) {
                        var _a;
                        return (__assign(__assign({}, prev), (_a = {}, _a[column.key] = __assign(__assign({}, prev[column.key]), { start: collapseISODate(value) }), _a)));
                    });
                } }),
            React.createElement(TextField, { label: "End", type: 'Date', value: (filter === null || filter === void 0 ? void 0 : filter.end) ? expandISODate(filter === null || filter === void 0 ? void 0 : filter.end) : undefined, onChange: function (_, value) {
                    setColumnFilters(function (prev) {
                        var _a;
                        return (__assign(__assign({}, prev), (_a = {}, _a[column.key] = __assign(__assign({}, prev[column.key]), { end: collapseISODate(value) }), _a)));
                    });
                } })));
    }
    else if (currencyFields.has(column.key)) {
        filterInputs = React.createElement(CurrencyFilterMenu, __assign({}, props));
    }
    return filterInputs ? (React.createElement(Stack, { styles: { root: { paddingTop: 10, width: 'fit-content' } } },
        React.createElement(Stack, { horizontal: true, tokens: stackTokens },
            React.createElement(Label, { styles: { root: { flexGrow: 1 } } }, column.name),
            React.createElement(IconButton, { iconProps: hideIcon, onClick: function () { return setFilterMenuColumn(null); } }),
            React.createElement(IconButton, { iconProps: cancelIcon, onClick: function () {
                    setColumnFilters(function (prev) { var curr = __assign({}, prev); delete curr[column.key]; return curr; });
                    setFilterMenuColumn(null);
                } })),
        React.createElement(Stack, { horizontal: true, tokens: stackTokens }, filterInputs))) : null;
};
function filterRows(data, filterText, textFields, filters) {
    var searchStr = filterText.trim().toLowerCase();
    return data.filter(function (row) {
        return textFields.some(function (key) { var _a; return (_a = row[key]) === null || _a === void 0 ? void 0 : _a.toLowerCase().includes(searchStr); }) &&
            rowMatchesColumnFilters(row, filters);
    });
}
function rowMatchesColumnFilters(row, filters) {
    var _a, _b;
    for (var key in filters) {
        if (isDateColumn(key)) {
            var filter = filters[key];
            var dateValue = row[key];
            if (filter.start && dateValue < filter.start) {
                return false;
            }
            if (filter.end && dateValue > filter.end) {
                return false;
            }
        }
        if (currencyFields.has(key)) {
            var filter = filters[key];
            var amount = row[key];
            if (((_a = filter.min) === null || _a === void 0 ? void 0 : _a.toString()) !== '' && amount < filter.min) {
                return false;
            }
            if (((_b = filter.max) === null || _b === void 0 ? void 0 : _b.toString()) !== '' && amount > filter.max) {
                return false;
            }
        }
    }
    return true;
}
var defaultSortDirection = function (fieldName) {
    return fieldName.toLowerCase().includes('date') ?
        SortDirection.desc :
        SortDirection.asc;
};
var searchStyles = { root: { maxWidth: 300 } };
export var SortDirection;
(function (SortDirection) {
    SortDirection[SortDirection["asc"] = 0] = "asc";
    SortDirection[SortDirection["desc"] = 1] = "desc";
})(SortDirection || (SortDirection = {}));
var templateObject_1;
