import * as React from 'react';
import { useState } from 'react';
import { FileEditor } from './file-editor';
import { FileViewer } from './file-viewer';
export var FileContent = function (_a) {
    var content = _a.content, onSaveContent = _a.onSaveContent, onCommit = _a.onCommit, pendingCommit = _a.pendingCommit, path = _a.path;
    var _b = useState(true), editMode = _b[0], setEditMode = _b[1];
    return editMode ?
        React.createElement(FileEditor, { content: content, onSaveContent: onSaveContent, onExitEditor: function () { return setEditMode(false); }, path: path }) :
        React.createElement(FileViewer, { content: content, onEdit: function () { return setEditMode(true); }, onCommit: onCommit, pendingCommit: pendingCommit, path: path });
};
