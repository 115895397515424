import { List } from '../list';
import { useCallback, useEffect, useMemo, useState } from 'react';
import * as React from 'react';
import { getFxHistory } from '../../services/server-api';
import { CommandBar, Panel } from '@fluentui/react';
import { useBoolean } from '@fluentui/react-hooks';
import { commandBarStyles } from '../styles';
import { FxForm } from './fx-form';
//TODO there's a lot of overlap between this component and Stocks - consider a way to unify for easier reuse.
//Payment and Income can probably share as well - all are views over data with add/edit on records that are not
//strictly represented by the view. 
export var Fx = function () {
    var _a = useState([]), fxHistory = _a[0], setFxHistory = _a[1];
    var reloadData = useCallback(function () { return getFxHistory().then(setFxHistory); }, []);
    useEffect(function () { reloadData(); }, [reloadData]);
    var _b = useBoolean(false), addFxTransaction = _b[0], _c = _b[1], showAddFxTransaction = _c.setTrue, hideAddFxTransaction = _c.setFalse;
    var commands = useCommandBarCommands(showAddFxTransaction, reloadData);
    var _d = useState(null), selectedItem = _d[0], setSelectedItem = _d[1];
    var hideEditFxTransaction = function () { return setSelectedItem(null); };
    return (React.createElement(React.Fragment, null,
        React.createElement(CommandBar, { items: commands, styles: commandBarStyles }),
        fxHistory &&
            React.createElement(List, { data: fxHistory, onClick: function (transaction) { return setSelectedItem(transaction.id); }, idField: 'id' }),
        React.createElement(Panel, { isOpen: addFxTransaction, headerText: "Add FX Transaction", onDismiss: hideAddFxTransaction },
            React.createElement(FxForm, { onClose: hideAddFxTransaction })),
        React.createElement(Panel, { isOpen: !!selectedItem, headerText: "Edit FX Transaction", onDismiss: hideEditFxTransaction },
            React.createElement(FxForm, { onClose: hideEditFxTransaction, id: selectedItem }))));
};
function useCommandBarCommands(onAddFxTransaction, reloadData) {
    var commands = useMemo(function () { return ([
        {
            split: true,
            key: 'newFxTransaction',
            text: 'FX Transaction',
            iconProps: { iconName: 'Add' },
            onClick: onAddFxTransaction,
        },
        {
            key: 'refresh',
            text: 'Refresh',
            iconProps: { iconName: 'Refresh' },
            onClick: reloadData
        }
    ]); }, [reloadData, onAddFxTransaction]);
    return commands;
}
