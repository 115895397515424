var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { useCallback, useEffect, useState } from 'react';
import * as React from 'react';
import { getStockTransaction, saveStockTransaction } from '../../services/server-api';
import { PrimaryButton, DefaultButton, Stack, TextField, Label } from '@fluentui/react';
import { stackTokens } from '../styles';
import { CurrencyInput } from '../currency-input';
import { StockAccountDropdown } from './stock-account-dropdown';
import { formatDay, formatTimeInput } from '../../common/date.utils';
import { currencySymbols } from '../currencies';
export var StocksForm = function (_a) {
    var onClose = _a.onClose, id = _a.id;
    var _b = useStockTransactionForm(onClose, id), stockTransaction = _b.stockTransaction, updateTransaction = _b.updateTransaction, updateAccount = _b.updateAccount, submitForm = _b.submitForm;
    if (!stockTransaction)
        return null; //TODO spinner
    return (React.createElement("form", null,
        React.createElement(Stack, { tokens: stackTokens },
            React.createElement(TextField, { label: 'Date', type: "date", onChange: updateTransaction, value: stockTransaction.transactionDate, name: "transactionDate" }),
            React.createElement(TextField, { label: 'Time', type: "time", step: "0.001", onChange: updateTransaction, value: stockTransaction.transactionTime, name: "transactionTime" }),
            React.createElement(StockAccountDropdown, { value: stockTransaction.accountId, onChange: updateAccount }),
            React.createElement(TextField, { label: "Ticker", name: "tickerSymbol", value: stockTransaction.tickerSymbol, onChange: updateTransaction }),
            React.createElement(Stack, { horizontal: true, tokens: stackTokens },
                React.createElement(Stack.Item, { grow: true },
                    React.createElement(TextField, { label: "Quantity", name: "quantity", type: "number", value: stockTransaction.quantity || stockTransaction.quantity === 0 ? stockTransaction.quantity.toString() : '', onChange: updateTransaction })),
                React.createElement(Stack.Item, { grow: 5 },
                    React.createElement(CurrencyInput, { amount: stockTransaction.unitPrice, currency: 'USD', name: "unitPrice", onChange: updateTransaction }))),
            React.createElement(Stack.Item, null,
                React.createElement(Label, null, "Total"),
                React.createElement(TextField, { borderless: true, underlined: true, readOnly: true, value: currencySymbols.usd + (stockTransaction.unitPrice * stockTransaction.quantity).toFixed(2) })),
            stockTransaction.quantity < 0 &&
                React.createElement(Stack.Item, null,
                    React.createElement(CurrencyInput, { label: "Cost Basis", amount: stockTransaction.costBasis, currency: 'USD', name: "costBasis", onChange: updateTransaction })),
            React.createElement(Stack.Item, null,
                React.createElement(CurrencyInput, { label: "Commission", amount: stockTransaction.commission, currency: 'USD', name: "commission", onChange: updateTransaction })),
            React.createElement(Stack, { horizontal: true, tokens: stackTokens },
                React.createElement(PrimaryButton, { onClick: submitForm }, "Save"),
                React.createElement(DefaultButton, { onClick: onClose }, "Cancel")))));
};
function useStockTransactionForm(onClose, stockTransactionId) {
    var _this = this;
    var _a = useState(stockTransactionId ? null : initializeTransaction()), stockTransaction = _a[0], setStockTransaction = _a[1];
    useEffect(function () {
        if (stockTransactionId) {
            getStockTransaction(stockTransactionId)
                .then(function (transaction) { return (__assign(__assign({}, transaction), { transactionDate: formatDay(transaction.transactionDate), transactionTime: formatTimeInput(transaction.transactionDate) })); })
                .then(setStockTransaction);
        }
    }, [stockTransactionId]);
    var updateTransaction = useCallback(function (_a) {
        var _b;
        var target = _a.target;
        setStockTransaction(__assign(__assign({}, stockTransaction), (_b = {}, _b[target.name] = target.value, _b)));
    }, [stockTransaction]);
    var updateAccount = useCallback(function (accountId) {
        setStockTransaction(__assign(__assign({}, stockTransaction), { accountId: accountId }));
    }, [stockTransaction]);
    var submitForm = useCallback(function () { return __awaiter(_this, void 0, void 0, function () {
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, saveStockTransaction(convertToTransactionDto(stockTransaction))];
                case 1:
                    _a.sent();
                    if (stockTransaction.id === -1) {
                        setStockTransaction(initializeTransaction());
                    }
                    else {
                        onClose();
                    }
                    return [2 /*return*/];
            }
        });
    }); }, [stockTransaction, onClose]);
    return { stockTransaction: stockTransaction, updateTransaction: updateTransaction, updateAccount: updateAccount, submitForm: submitForm };
}
function initializeTransaction() {
    var today = formatDay(new Date());
    return {
        id: -1,
        accountId: null,
        accountName: null,
        transactionDate: today,
        transactionTime: '',
        unitPrice: null,
        commission: null,
        costBasis: null,
        tickerSymbol: '',
        quantity: null
    };
}
function convertToTransactionDto(transaction) {
    return __assign(__assign({}, transaction), { transactionDate: "".concat(transaction.transactionDate, "T").concat(transaction.transactionTime || '00:00:00', "Z") });
}
