import * as React from 'react';
import { useCategories } from './categories';
import { useMemo } from 'react';
import { ComboBox } from '@fluentui/react';
export var CategoryDropdown = function (_a) {
    var value = _a.value, onChange = _a.onChange, showLabel = _a.showLabel, _b = _a.label, label = _b === void 0 ? 'Category' : _b;
    var categories = useCategories();
    var options = useMemo(function () { return categories === null || categories === void 0 ? void 0 : categories.map(function (category) { return ({
        key: category.id,
        text: category.name,
        data: category
    }); }); }, [categories]);
    return (React.createElement(ComboBox, { autoComplete: "on", placeholder: "Select category", label: showLabel && label, selectedKey: value, onChange: function (ev, option) { return onChange(option.key); }, onRenderOption: function (option) { return React.createElement(React.Fragment, null, option.data.hierarchicalName); }, options: options }));
};
