import * as React from 'react';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { getFuelLog } from '../../services/server-api';
import { CommandBar, Panel, List as FluentList, Text } from '@fluentui/react';
import { commandBarStyles } from '../styles';
import { useBoolean } from '@fluentui/react-hooks';
import { LogFuelForm } from './log-fuel-form';
import { FuelLogCard } from './fuel-log-card';
import { PaymentForm } from '../payments/payment-form';
export var FuelHistory = function () {
    var _a = useState(), fuelLog = _a[0], setFuelLog = _a[1];
    var reloadData = useCallback(function () {
        getFuelLog().then(setFuelLog);
    }, [setFuelLog]);
    useEffect(function () {
        reloadData();
    }, [reloadData]);
    var _b = useBoolean(false), addFuelLog = _b[0], _c = _b[1], showAddFuelLog = _c.setTrue, hideAddFuelLog = _c.setFalse;
    var _d = useState(null), selectedPayment = _d[0], setSelectedPayment = _d[1];
    var hideEditPayment = function () { return setSelectedPayment(null); };
    var commands = useCommandBarCommands(showAddFuelLog, reloadData);
    return (React.createElement(React.Fragment, null,
        React.createElement(CommandBar, { items: commands, styles: commandBarStyles }),
        fuelLog && React.createElement(FluentList, { items: fuelLog.fuelLog, onRenderCell: function (f) {
                return React.createElement(Text, null,
                    React.createElement(FuelLogCard, { fuelLog: f, onViewPayment: function () { return setSelectedPayment(f.paymentId); } }));
            } }),
        React.createElement(Panel, { isOpen: addFuelLog, headerText: "Log Fuel", onDismiss: hideAddFuelLog },
            React.createElement(LogFuelForm, { onClose: hideAddFuelLog, onSave: reloadData, data: fuelLog === null || fuelLog === void 0 ? void 0 : fuelLog.fuelLog[0] })),
        React.createElement(Panel, { isOpen: !!selectedPayment, headerText: "Edit Payment", onDismiss: hideEditPayment },
            React.createElement(PaymentForm, { onClose: hideEditPayment, id: selectedPayment }))));
};
function useCommandBarCommands(onAddFuelLog, reloadData) {
    var commands = useMemo(function () { return ([
        {
            split: true,
            key: 'addFuelLog',
            text: 'Log Fuel',
            iconProps: { iconName: 'Add' },
            onClick: onAddFuelLog,
        },
        {
            key: 'refresh',
            text: 'Refresh',
            iconProps: { iconName: 'Refresh' },
            onClick: reloadData
        }
    ]); }, [reloadData, onAddFuelLog]);
    return commands;
}
