import { List } from '../list';
import { useCallback, useEffect, useMemo, useState } from 'react';
import * as React from 'react';
import { getAllStocks, getStockHoldingsSummary } from '../../services/server-api';
import { CommandBar, Panel } from '@fluentui/react';
import { useBoolean } from '@fluentui/react-hooks';
import { commandBarStyles } from '../styles';
import { StocksForm } from './stocks-form';
export var Stocks = function () {
    var _a = useState([]), stocks = _a[0], setStocks = _a[1];
    var _b = useState([]), stockHoldingsSummary = _b[0], setStockHoldingsSummary = _b[1];
    var reloadData = useCallback(function () {
        getAllStocks().then(setStocks);
        getStockHoldingsSummary().then(setStockHoldingsSummary);
    }, []);
    useEffect(function () { reloadData(); }, [reloadData]);
    var _c = useBoolean(false), addStockTransaction = _c[0], _d = _c[1], showAddStockTransaction = _d.setTrue, hideAddStockTransaction = _d.setFalse;
    var commands = useCommandBarCommands(showAddStockTransaction, reloadData);
    var _e = useState(null), selectedItem = _e[0], setSelectedItem = _e[1];
    var hideEditStockTransaction = function () { return setSelectedItem(null); };
    return (React.createElement(React.Fragment, null,
        React.createElement(CommandBar, { items: commands, styles: commandBarStyles }),
        stockHoldingsSummary &&
            React.createElement(List, { data: stockHoldingsSummary }),
        stocks &&
            React.createElement(List, { data: stocks, onClick: function (transaction) { return setSelectedItem(transaction.id); }, idField: 'id' }),
        React.createElement(Panel, { isOpen: addStockTransaction, headerText: "Add Stock Transaction", onDismiss: hideAddStockTransaction },
            React.createElement(StocksForm, { onClose: hideAddStockTransaction })),
        React.createElement(Panel, { isOpen: !!selectedItem, headerText: "Edit Stock Transaction", onDismiss: hideEditStockTransaction },
            React.createElement(StocksForm, { onClose: hideEditStockTransaction, id: selectedItem }))));
};
function useCommandBarCommands(onAddStockTransaction, reloadData) {
    var commands = useMemo(function () { return ([
        {
            split: true,
            key: 'newStockTransaction',
            text: 'Stock Transaction',
            iconProps: { iconName: 'Add' },
            onClick: onAddStockTransaction,
        },
        {
            key: 'refresh',
            text: 'Refresh',
            iconProps: { iconName: 'Refresh' },
            onClick: reloadData
        }
    ]); }, [reloadData, onAddStockTransaction]);
    return commands;
}
