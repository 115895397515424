export var fileIconName = 'TextDocument';
export var folderIconName = 'FabricFolder';
export var downloadIconName = 'Download';
export var externalIconName = 'ArrowUpRight';
export var editIcon = { iconName: 'Edit' };
export var saveIcon = { iconName: 'Save' };
export var cancelIcon = { iconName: 'Cancel' };
export var checkIcon = { iconName: 'CheckMark' };
export var addIcon = { iconName: 'Add' };
export var hideIcon = { iconName: 'Hide3' };
export var commitIcon = { iconName: 'BranchCommit' };
export var saveAndCloseIcon = { iconName: 'SaveAndClose' };
export var fileIcon = { iconName: fileIconName };
export var wrapLinesIcon = { iconName: 'LineStyle' };
export var toggleEditorIcon = { iconName: 'LocationOutline' };
export var searchIcon = { iconName: 'Search' };
export var downloadIcon = { iconName: downloadIconName };
export var uploadIcon = { iconName: 'Upload' };
